import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Snackbar } from "@mui/material";
// import { Snackbar, SnackbarContent } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
// import { connect } from 'react-redux';
// import { updateLogin, setUUID, setToken } from "../../actions";
import { updateLogin } from "../../actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import { url } from "../../url";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  // const [token, setMyToken] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const islogin = useSelector((state) => state.islogin);
  // const reducer_token = useSelector((state) => state.toke);

  useEffect(() => {
    if (islogin) {
      navigate("/main");
    }
  }, [navigate, islogin]);
  useEffect(() => {
    //detect data, rerender when data is changed.
    fetchdate();
  }, []);

  const handleChangeUsername = (username) => {
    setUsername(username.replaceAll(" ", ""));
  };

  const fetchdate = () => {
    //use anonymous function in function component
    // if(reducer_token===''){

    return fetch(url("/b/customer/token/"), {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        // let cookieheader = response.headers.get('Set-Cookie');
        return response.json();
      })
      .then((res) => {
        // console.log(res);
        // Cookies.set('asd',"asdasd")
        Cookies.set("token", res.token);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const login_execute = () => {
    if (username === "") {
      alert("Username empty!");
      return;
    }
    fetch(url("/b/customer/login/"), {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CSRFToken": Cookies.get("token"),
      },
      body: JSON.stringify({ email: username, password }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.result === "Success") {
          const action = updateLogin(true);
          dispatch(action);
          Cookies.set("islogin", "true", { expires: 7, path: "/" });
          navigate("/main");
        } else {
          alert(res.result);
        }
      })
      .then((res) => {
        fetchdate().then(()=>{console.log("test")});
      });
  };

  return (
    <Card
      sx={{ width: 300, padding: 2, fontSize: 20, opacity: 0.7, zIndex: 1 }}
    >
      <Typography>For Registered Users</Typography>
      <Box
        sx={{
          "& .MuiTextField-root": { mt: 0.5 },
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
        }}
      >
        <TextField
          fullWidth
          id="registeredUsername"
          name="registeredUsername"
          label="Username"
          variant="filled"
          required
          value={username}
          onChange={(e) => handleChangeUsername(e.target.value)} //use this method rather than getelementbyid method
        />
        <TextField
          fullWidth
          id="registeredPassword"
          name="registeredPassword"
          label="Password"
          variant="filled"
          type="password"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Box sx={{ marginTop: "5px" }}>
          <Link to="/reset" fontSize={80}>
            <Typography>Forgot your password?</Typography>{" "}
          </Link>
        </Box>
        <br />
        <Button
          sx={{ mt: 1 }}
          type="submit"
          variant="outlined"
          onClick={() => {
            login_execute();
          }}
        >
          Login
        </Button>
        <Snackbar></Snackbar>
      </Box>
    </Card>
  );
}

export default Login;
