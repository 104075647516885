import { List, Typography } from "@mui/material";
// import { IconButton, List, Typography } from "@mui/material";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Colors } from "../theme";
// import { Directions } from "@mui/icons-material";

//container
export const AppbarContainer = styled(Box)(() => ({
  display: "flex", //默认是水平方向排放 flex box的格式
  flexDirection: "row", //可以调节水平或者垂直方向
  marginTop: 0,
  marginLeft: 0,
  // justifyContent:'center', //每一行中的所有元素在这一行中的水平方向的位置
  alignItems: "center", // 每一行中的所有元素在这一行的垂直方向的位置
  padding: "8px 8px",
  // backgroundColor:'#0000ff',
}));

//header
export const AppbarHeader = styled(Typography)(() => ({
  //一种字体格式
  padding: "4px", //上下左右的内边距
  flexGrow: 1,
  fontSize: "4em",
  fontFamily: '"Montez", "cursive"',
  // width:"100px",
  color: Colors.secondary,
  // "&:hover": {
  //   animation: `${textPopUpTop} 0.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) both`,
  // },
}));

export const MyList = styled(List)(({ theme, type }) => ({
  display: type === "row" ? "flex" : "block",
  flexGrow: 3,
  justifyContent: "center",
  alignItems: "center",
}));
