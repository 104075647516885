import React, { useState, useEffect } from "react";
import AWS from "aws-sdk";
import MuiPhoneNumber from "material-ui-phone-number";
import { RegionDropdown } from "react-country-region-selector";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { url } from "../../url";
import MuiAlert from "@mui/material/Alert";
import {
  Card,
  Button,
  Snackbar,
  Typography,
  TextField,
  Box,
  Input,
  CircularProgress,
} from "@mui/material";

const Registration = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageURL, setImageURL] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [taxId, settaxId] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [country] = useState("United States");
  const [region, setRegion] = useState("");
  const [zipcode, setZipcode] = useState("");
  // const [password, setPassword] = useState("");
  // const [confirmPassword, setConfirmPassword] = useState("");

  const [isValid_email, setisValid_email] = useState(true);
  const [isValid_phone, setisValid_phone] = useState(true);
  const [isValid_zipcode, setisValid_code] = useState(true);
  // const [isValid_password, setisValid_password] = useState(false);
  // const [isValid_password_conform, setisValid_password_conform] =
  //   useState(false);
  // const [token, setMyToken] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const islogin = useSelector((state) => state.islogin);
  // const reducer_token = useSelector(state => state.token);
  const navigate = useNavigate();
  const [notification, setNotification] = useState("");

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={12} ref={ref} variant="filled" {...props} />;
  });

  useEffect(() => {
    if (islogin) {
      navigate("/main");
    }
  }, [navigate, islogin]);

  useEffect(() => {
    AWS.config.update({
      accessKeyId: "AKIATQ4Z2DERYT2SGE4I",
      secretAccessKey: "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",
      region: "us-east-2",
    });
  }, []);

  const handleCloseNotification = () => {
    setNotification("");
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleTaxidChange = (event) => {
    const value = event.target.value;
    const sanitizedValue = value.replace(/[^0-9]/g, "");

    let formattedValue = "";
    if (sanitizedValue.length <= 1) {
      formattedValue = sanitizedValue;
    } else if (sanitizedValue.length <= 6) {
      formattedValue =
        sanitizedValue.slice(0, 1) + "-" + sanitizedValue.slice(1);
    } else if (sanitizedValue.length <= 10) {
      formattedValue =
        sanitizedValue.slice(0, 1) +
        "-" +
        sanitizedValue.slice(1, 6) +
        "-" +
        sanitizedValue.slice(6);
    } else if (sanitizedValue.length <= 11) {
      formattedValue =
        sanitizedValue.slice(0, 1) +
        "-" +
        sanitizedValue.slice(1, 6) +
        "-" +
        sanitizedValue.slice(6, 10) +
        "-" +
        sanitizedValue.slice(10);
    } else {
      formattedValue =
        sanitizedValue.slice(0, 1) +
        "-" +
        sanitizedValue.slice(1, 6) +
        "-" +
        sanitizedValue.slice(6, 10) +
        "-" +
        sanitizedValue.slice(10, 11);
    }
    settaxId(formattedValue);
  };

  const handleEmailChange = (event) => {
    const inputValue = event.target.value;
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    setEmail(inputValue);
    setisValid_email(emailRegex.test(inputValue));
  };

  const handlePhoneChange = (event) => {
    const inputValue = event;
    // const phoneRegex = /^\d{10}$/; //
    setPhone(inputValue);
    // setIsValid(phoneRegex.test(inputValue));
    setisValid_phone(inputValue.length === 14);
  };

  const handleZipCodeChange = (event) => {
    const inputValue = event.target.value;
    const zipCodeRegex = /^\d{5}$/;
    setZipcode(inputValue);
    setisValid_code(zipCodeRegex.test(inputValue));
  };

  // const handlePassword = (event) => {
  //   const inputValue = event.target.value;
  //   setPassword(inputValue);
  //   setisValid_password(inputValue.length > 7);
  // };

  // const handlePasswordConfirm = (event) => {
  //   var inputValue = event.target.value;
  //   setConfirmPassword(inputValue);
  //   if (password === event.target.value) {
  //     setisValid_password_conform(true);
  //   } else {
  //     setisValid_password_conform(false);
  //   }
  // };

  const register_execute = () => {
    var formData = {
      first_name: firstName,
      last_name: lastName,
      company: companyName,
      tax_id: taxId,
      email: email,
      phone: phone.replace(/\D/g, ""),
      region: region,
      address1: addressLine1,
      address2: addressLine2,
      city: city,
      state: region,
      zipcode: zipcode,
      // password: password,
      tax_url: imageURL,
    };
    const testData = {
      first_name: firstName,
      last_name: lastName,
      company: companyName,
      tax_id: taxId,
      email: email,
      phone: phone.replace(/\D/g, ""),
      region: region,
      address1: addressLine1,
      // address2: addressLine2,
      city: city,
      state: region,
      zipcode: zipcode,
      // password: password,
      // tax_url:imageURL,
    };

    const isAnyFieldEmpty = Object.values(testData).some(
      (value) => value === ""
    );

    if (isAnyFieldEmpty || selectedFile === null) {
      alert("All fields marked with an asterisk (*) are required.");
    } else if (
      !(
        (isValid_email && isValid_phone && isValid_zipcode)
        // &&
        // isValid_password_conform &&
        // isValid_password
      )
    ) {
      var alert_information = "";
      if (!isValid_email)
        alert_information = alert_information + "Emial format error!\n";
      if (!isValid_phone)
        alert_information = alert_information + "Phone format error!\n";
      if (!isValid_zipcode)
        alert_information = alert_information + "Zipcode format error!\n";
      // if (!isValid_password)
      //   alert_information =
      //     alert_information + "Password must be at least 8 characters long.!\n";
      // if (!isValid_password_conform)
      //   alert_information =
      //     alert_information + "The passwords entered do not match!\n";
      alert(alert_information);
    } else {
      if (selectedFile) {
        console.log(selectedFile);
        const params = {
          Bucket: "imagelenwell",
          Key:
            `tax-documents/${Date.now()}-${companyName}` +
            "." +
            selectedFile.name.split(".").pop(), //test
          Body: selectedFile,
          ACL: "public-read",
          ContentType: selectedFile.type,
        };
        setIsLoading(true);
        const s3 = new AWS.S3();
        s3.upload(params, (err, data) => {
          if (err) {
            console.log("Upload failed:", err);
          } else {
            console.log("Upload successful. Image URL:", data.Location);
            setImageURL(data.Location);

            formData = {
              first_name: firstName,
              last_name: lastName,
              company: companyName,
              tax_id: taxId,
              email: email,
              phone: phone.replace(/\D/g, ""),
              region: region,
              address1: addressLine1,
              address2: addressLine2,
              city: city,
              state: region,
              zipcode: zipcode,
              // password: password,
              tax_url: data.Location,
            };
            fetch(url("/b/waitlist/register/"), {
              method: "POST",
              credentials: "include",
              headers: {
                "X-CSRFToken": Cookies.get("token"),
              },
              body: JSON.stringify(formData),
            })
              .then((res) => res.json())
              .then((res) => {
                setIsLoading(false);
                alert(res.result);
              });
          }
        });
      }
    }
  };

  return (
    <Card
      sx={{ width: 350, padding: 2, fontSize: 20, opacity: 1, zIndex: 9999 }}
    >
      <Typography>For New Users</Typography>
      <Box sx={{}}>
        <TextField
          label="First Name"
          variant="filled"
          required
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          style={{ marginTop: "5px", width: "100%" }}
        />

        <TextField
          label="Last Name"
          variant="filled"
          required
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          style={{ marginTop: "5px", width: "100%" }}
        />

        <TextField
          label="Company Name"
          variant="filled"
          required
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
          style={{ marginTop: "5px", width: "100%" }}
        />

        <TextField
          label="Tax ID"
          variant="filled"
          required
          value={taxId}
          onChange={(e) => handleTaxidChange(e)}
          style={{ marginTop: "5px", width: "100%" }}
        />

        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Typography style={{ marginLeft: "5px" }}>*</Typography>
          <Box sx={{ display: "flex", flexDirection: "column", flexGrow: "1" }}>
            <Typography>Upload Your Tax ID File</Typography>
            <Input
              type="file"
              onChange={handleFileSelect}
              inputProps={{
                accept: "image/*,application/pdf",
              }}
            />
          </Box>
          {/* <Button variant="outlined" onClick={handleUpload}>
            Upload file
          </Button> */}
        </Box>
        <TextField
          label="Email"
          variant="filled"
          type="email"
          required
          value={email}
          onChange={(e) => handleEmailChange(e)}
          style={{ marginTop: "10px", width: "100%" }}
          helperText={!isValid_email ? "Invalid email address" : ""}
        />
        <Box style={{ display: "flex", flexDirection: "row" }}>
          <Typography style={{ marginLeft: "5px" }}>*</Typography>
          <MuiPhoneNumber
            style={{
              height: "50px",
              // innerHeight:"300px"
              marginTop: "10px",

              flexGrow: 1,
            }}
            placeholder="(123) 123-4567"
            disableCountryCode
            onlyCountries={["us"]}
            countryCodeEditable="false"
            defaultCountry={"us"}
            onChange={(e) => {
              handlePhoneChange(e);
            }}
          />
        </Box>
        <TextField
          label="Address Line 1"
          variant="filled"
          required
          value={addressLine1}
          onChange={(e) => setAddressLine1(e.target.value)}
          style={{ marginTop: "5px", width: "100%" }}
        />
        <TextField
          label="Address Line 2"
          variant="filled"
          // required
          value={addressLine2}
          onChange={(e) => setAddressLine2(e.target.value)}
          style={{ marginTop: "5px", width: "100%" }}
        />
        <Box
          style={{ marginTop: "5px", display: "flex", flexDirection: "row" }}
        >
          <TextField
            label="City"
            variant="filled"
            // type="email"
            required
            value={city}
            onChange={(e) => setCity(e.target.value)}
            style={{ marginTop: "5px", width: "50%" }}
          />
          <Typography style={{ marginLeft: "5px" }}>*</Typography>
          <RegionDropdown
            style={{
              height: "50px",
              // marginTop: "10px",
              marginTop: "5px",
              width: "50%",
              // innerHeight:"300px"
            }}
            country={country}
            labelType="short"
            valueType="short"
            value={region}
            onChange={(val) => setRegion(val)}
          />
        </Box>
        <TextField
          label="Zipcode"
          variant="filled"
          // type="email"
          required
          value={zipcode}
          onChange={(e) => handleZipCodeChange(e)}
          style={{ marginTop: "5px", width: "100%" }}
          helperText={!isValid_zipcode ? "Invalid Zipcode" : ""}
        />

        {/* <TextField
          label="Password"
          variant="filled"
          type="password"
          required
          value={password}
          onChange={(e) => handlePassword(e)}
          style={{ marginTop: "5px", width: "100%" }}
        />

        <TextField
          label="Confirmed Password"
          variant="filled"
          type="password"
          required
          value={confirmPassword}
          onChange={(e) => handlePasswordConfirm(e)}
          style={{ marginTop: "5px", width: "100%" }}
        /> */}
        <br />
        <br />
        <Button
          sx={{ width: "100%" }}
          type="submit"
          variant="outlined"
          onClick={register_execute}
        >
          {isLoading ? (
            <CircularProgress size={"24px"} />
          ) : (
            <Typography>Register</Typography>
          )}
        </Button>
        <br />
        <Snackbar
          open={Boolean(notification)}
          autoHideDuration={3000}
          onClose={handleCloseNotification}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          style={{ marginTop: "40px", opacity: 0.9 }} // Adjust the marginTop value to move the Snackbar higher
        >
          <Alert
            onClose={handleCloseNotification}
            severity="success"
            sx={{ width: "100%" }}
          >
            {notification}
          </Alert>
        </Snackbar>
      </Box>
    </Card>
  );
};

export default Registration;
