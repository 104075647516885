import Cookies from "js-cookie";
export const checkToken = () => {
  return (dispatch) => {
    // const storedIsLoggedIn = localStorage.getItem('isLoggedIn');
    // var to="";
    // const fetchdate = () => {
    //   //use anonymous function in function component
    //   fetch("http://localhost:8000/b/customer/token/")
    //     .then((response) => {
    //       return response.json();
    //     })
    //     .then((data) => {
    //       // this.setState({Data:data})
    //       // console.log(data)
    //       // console.log(data.token)
    //       setToken(data.token);
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //     });
    // };
    // fetchdate();
    // const isLoggedIn = storedIsLoggedIn === 'true';
  };
};

export const setToken = (newData) => ({
  type: "SET_TOKEN",
  payload: newData,
});

export const updateData = (newData) => ({
  type: "UPDATE_DATA",
  payload: newData,
});

// actions.js

export const addToCart = (product, quantity) => {
  return {
    type: "ADD_TO_CART",
    payload: {
      product,
      quantity,
    },
  };
};

export const initializeCart = (cart) => {
  // console.log(cart)
  return {
    type: "INITIALIZE_CART",
    payload: {
      cart,
    },
  };
};

// actions.js

export const removeFromCart = (productId) => {
  return {
    type: "REMOVE_FROM_CART",
    payload: productId,
  };
};

export const updateToCart = (update_product, update_quantity) => {
  return {
    type: "UPDATE_TO_CART",
    payload: {
      update_product,
      update_quantity,
    },
  };
};

export const updateCurretnProduct = (newData) => ({
  type: "UPDATE_CURRENT_PRODUCT",
  payload: newData,
});

export const updateLogin = (newData) => ({
  type: "UPDATE_LOGIN",
  payload: newData,
});
// actions.js

export const checkLoginStatus = () => {
  return (dispatch) => {
    // const storedIsLoggedIn = localStorage.getItem('isLoggedIn');
    // var uuid="";
    const islogin = Cookies.get("islogin");
    // Cookies.set('islogin', 'false');
    if (islogin === "true") {
      // uuid=localStorage.getItem('uuid');
      // uuid=Cookies.get('uuid');
      // dispatch({
      //   type: 'SET_UUID',
      //   payload: uuid,
      // });
      dispatch({
        type: "UPDATE_LOGIN",
        payload: true,
      });
    } else {
      // dispatch({
      //   type: 'SET_UUID',
      //   payload: null,
      // });
      dispatch({
        type: "UPDATE_LOGIN",
        payload: false,
      });
      // console.log("test!!")
    }
    // const isLoggedIn = storedIsLoggedIn === 'true';
  };
};

export const setUUID = (uuid) => {
  return {
    type: "SET_UUID",
    payload: uuid,
  };
};
