import React, { useEffect, useRef } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Button,
  ClickAwayListener,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
// import { Link } from "react-router-dom";
// import { NavLink } from "react-router-dom";
import menuData from "./menu_data.json";
import { useNavigate } from "react-router-dom";

const DrawerMenu = ({ isOpen, onClose, time }) => {
  const [open, setOpen] = React.useState({});
  const [mytime, setTime] = React.useState(time); // WARNING, CRASH IF DELETE
  // console.log(isOpen);
  const [private_isOpen, set_PrivateIsOpen] = React.useState(isOpen);
  // console.log(private_isOpen);
  const navigate = useNavigate();
  const drawerRef = useRef([]);

  // useEffect(() => {
  //   set_PrivateIsOpen(isOpen);
  //   // console.log(private_isOpen)
  //   console.log("first");
  // }, []);

  // console.log(isOpen);
  // console.log(private_isOpen);
  useEffect(() => {
    // console.log(isOpen);
    set_PrivateIsOpen(isOpen);
    setTime(time);
    // console.log(time);
    // console.log(private_isOpen);
    // console.log(time);
  }, [time, isOpen]);

  const handleClickAway = (event) => {
    // console.log("menu listener  ")
    // if (
    //   drawerRef.current.includes(event.target)
    // ) {
    //   return;
    // }
    // set_PrivateIsOpen(false);
  };
  const toggleDrawer = () => {
    set_PrivateIsOpen(false);
  };
  // prevent

  const handleMenuClick = (event) => {
    event.stopPropagation();
  };
  const handleClick = (index) => {
    setOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const renderMenuItems = (items, parentIndex = "") => {
    return items.map((item, index) => {
      const currentIndex = parentIndex ? `${parentIndex}-${index}` : `${index}`;

      if (item.label === "Special Promotion") {
        return (
          <ListItem
            key={currentIndex}
            style={{ color: "#777" }} // Use any desired color for disabled look
          >
            <ListItemText primary={item.label} />
          </ListItem>
        );
      } else if (item.subMenuItems.length > 0) {
        const isOpen = open[currentIndex];
        return (
          <React.Fragment key={currentIndex}>
            <ListItem
              onClick={() => {
                handleClick(currentIndex);
              }}
              style={{ color: "#87CEFF" }}
              component={Button}
            >
              <ListItemText primary={item.label} />
              {/* {console.log(item.label)} */}
              {isOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {renderMenuItems(item.subMenuItems, currentIndex)}
              </List>
            </Collapse>
          </React.Fragment>
        );
      }

      return (
        <ListItem
          key={currentIndex}
          onClick={() => {
            navigate("/productlist/" + item.label);
            setOpen(false);
            isOpen = false;
            set_PrivateIsOpen(false);
          }}
          style={{ color: "#2F4F4F" }}
          component={Button}
        >
          <ListItemText primary={item.label} style={{ fontSize: "10px" }} />
        </ListItem>
      );
    });
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Drawer
        anchor="left"
        open={private_isOpen}
        onClose={toggleDrawer}
        ref={(ref) => (drawerRef.current[0] = ref)}
      >
        <List onClick={handleMenuClick}>
          {renderMenuItems(menuData)}

          <ListItem
            component={Button}
            onClick={() => {
              setOpen(false);
              navigate("/buyback");
              set_PrivateIsOpen(false);
            }}
            style={{ color: "#87CEFF" }}
          >
            <ListItemText primary={"Buy Back"} />
            {/* {isOpen ? <ExpandLess /> : <ExpandMore />} */}
          </ListItem>
        </List>
      </Drawer>
    </ClickAwayListener>
  );
};

export default DrawerMenu;
