import React, { useState, useEffect } from "react";
import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Box,
  Paper,
} from "@mui/material";
import { useSelector } from "react-redux";
import AppbarDesktop from "../main/appbar/appbarDesktop";
import Menu from "../main/menu/menu";
import { useMediaQuery } from "react-responsive";
import MobileMenu from "../main/menu/mobile_menu";
import { useNavigate, Link } from "react-router-dom";
import { url } from "../url";
import { PRICING_TABLE_CONSTANTS } from "./options";

const BuybackPricingTable = () => {
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const [brand, setBrand] = useState("iPhone");
  const [brands, setBrands] = useState([]);
  const [data, setData] = useState(null);
  const isLogin = useSelector((state) => state.islogin);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Lenwell | Buyback";
    fetch(url(`/b/buyback/pricing-table/`), {
      method: "GET",
      credentials: "include",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setData(res.data);
        setBrands(res.brands);
        // console.log(res);
        // setIsLoading(false);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    //fix navigate before first render by putting navigate function into useEffect.
    if (!isLogin) {
      // console.log("main navigate to /")
      navigate("/");
    }
  }, [navigate, isLogin]);

  const handleBrandSelect = (name) => {
    setBrand(name);
  };

  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }

  return (
    <Box>
      <AppbarDesktop />
      {isMobile ? <MobileMenu /> : <Menu />}
      {data && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: isMobile ? "10px" : "40px",
          }}
        >
          <Typography
            sx={{
              width: { xs: "80%", md: "50%" },
              marginBottom: "20px",
              fontSize: { xs: "12px", md: "inherit" },
            }}
          >
            Please note that prices are subject to weekly fluctuations and may
            be subject to delays. For the most accurate and up-to-date
            information, we recommend reaching out to the merchant directly.
            Feel free to contact the merchant for specific details.
          </Typography>
          <Button
            variant="contained"
            component={Link}
            style={{ backgroundColor: "#FF0000" }}
            to={"/buyback/label"}
          >
            request a free label
          </Button>
          <Box
            sx={{
              margin: { xs: "10px", md: "20px" },
              display: "flex",
              flexDirection: "row",
            }}
          >
            {brands.map((item, index) => (
              <Box sx={{ marginLeft: "1%" }} key={index + item}>
                <Button
                  variant="contained"
                  onClick={() => handleBrandSelect(item)}
                >
                  {item}
                </Button>
              </Box>
            ))}
          </Box>
          <TableContainer
            component={Paper}
            sx={{ width: { xs: "98%", md: "60%" }, marginBottom: "2%" }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  {PRICING_TABLE_CONSTANTS[brand].header.map((item, index) => (
                    <TableCell key={"table-header-" + item + index}>
                      {item}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  .filter((row) => row["brand"] === brand)
                  .map((row, index) => (
                    <TableRow key={"table-row-" + row.model + index}>
                      {PRICING_TABLE_CONSTANTS[brand].key.map((item, index) => (
                        <TableCell key={"table-cell-" + item + index}>
                          {item === "model" ? row[item] : "$" + row[item]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Typography
            sx={{
              width: { xs: "80%", md: "50%" },
              marginBottom: "5%",
              fontSize: { xs: "12px", md: "inherit" },
            }}
          >
            <b>Grade A:</b>
            <br />
            Original with good LCD and touch. <br />
            <b>Grade B:</b>
            <br />
            Original with pixel spot/multiple pixel spots. Back light
            Leakage/Bright Spot.
            <br />
            <b>Grade C:</b>
            <br />
            Original with small color pressure damage area (normally in blue,
            red, green and sometimes pink). Noticeable Stuck Pixel Clusters and
            Yellow Corners Due to Age (usually iPhone 6 & 6+). Previously
            Refurbished LCD's Bad Touch (For OLED models).
            <br />
            <b>Bad Screen:</b>
            <br />
            Lines, burns, missing flex, yellow area, water or damage, large
            color pressure damage. <br />
            <b>BL = backlight</b>
            <br />
            <b>NT = no touch</b>
            <br />
          </Typography>
        </Box>
      )}
    </Box>
  );
};
export default BuybackPricingTable;
