import React, { useState, useEffect } from "react";
import AppbarDesktop from "../../main/appbar";
import { Box, Typography, Paper, TextField, Button } from "@mui/material";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import LockIcon from "@mui/icons-material/Lock";
import Cookies from "js-cookie";
import { updateLogin } from "../../actions";
import { url } from "../../url";
import { useMediaQuery } from "react-responsive";
import MobileMenu from "../../main/menu/mobile_menu";
import Menu from "../../main/menu/menu";
import LeftMenu from "../left_menu/menu";

const ChangePassword = (props) => {
  // const { islogin } = props;
  //   const uuid = useSelector((state) => state.uuid);
  const islogin = useSelector((state) => state.islogin);

  const [oldpassword, setOldPassword] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 600 });

  useEffect(() => {
    document.title = "Lenwell | ChangePassword"; //
  }, []);

  const change_execute = () => {
    const formData = {
      oldpassword: oldpassword,
      newpassword: newpassword,
      confirmpassword: confirmpassword,
    };
    const sentData = {
      old_password: oldpassword,
      new_password: newpassword,
    };
    const isAnyFieldEmpty = Object.values(formData).some(
      (value) => value === ""
    );
    if (isAnyFieldEmpty) {
      alert("All fields marked with an asterisk (*) are required.");
    } else if (newpassword.length < 8) {
      alert("Password must be at least 8 characters long.");
    } else if (newpassword !== confirmpassword) {
      alert("The passwords entered do not match!");
    } else {
      //fetch
      // console.log(sentData)
      fetch(url("/b/customer/update-password/"), {
        method: "PUT",
        credentials: "include",
        headers: { "X-CSRFToken": Cookies.get("token") },
        body: JSON.stringify(sentData),
      })
        .then((response) => {
          if (response.status === 403) {
            Cookies.set("islogin", "false", { path: "/" });
            Cookies.remove("csrftoken");
            const action = updateLogin(false);
            dispatch(action);
            navigate("/auth");
          }
          if (response.status === 404) {
            navigate("/");
          }
          return response.json();
        })
        .then((res) => {
          // console.log(res)

          if (res.result === "incorrect") {
            alert("Old Password Error!");
          } else if (res.result === "fail") {
            alert("Failed to change!");
          } else if (res.result === "success") {
            alert("Password successfully updated!");
            setOldPassword("");
            setNewPassword("");
            setConfirmPassword("");
            Cookies.set("islogin", "false", { path: "/" });
            const action = updateLogin(false);
            dispatch(action);
            navigate("/auth");
          }

          Cookies.set("islogin", "false", { path: "/" });
          //   const timer = setTimeout(() => {}, 100);
        });
    }
  };
  if (islogin === false) {
    return <Navigate to="/" />;
  }

  return (
    <Box>
      <AppbarDesktop />
      {isMobile ? <MobileMenu sx={{ flexGrow: 1 }} /> : <Menu />}
      {isMobile ? <Link to="/account">return to account menu</Link> : null}

      <Box
        sx={{
          marginLeft: !isMobile && "12%",
          marginRight: !isMobile && "10%",
          display: "flex",
          flex: 1,
          alignItems: isMobile ? "center" : "stretch",
          marginTop: isMobile ? 5 : 0,
          flexDirection: isMobile ? "column" : "row",
          paddingBottom: "2%",
        }}
      >
        {!isMobile && (
          <Paper>
            <LeftMenu />
          </Paper>
        )}
        <Box
          sx={{
            width: isMobile ? "100%" : "90%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "30px",
          }}
        >
          <Typography variant="h4">
            <LockIcon />
            Change Password
          </Typography>

          <TextField
            label="Old Password"
            variant="filled"
            required
            type="password"
            value={oldpassword}
            onChange={(e) => setOldPassword(e.target.value)}
            sx={{ mt: 2, width: isMobile ? "70%" : "50%" }}
          />
          <TextField
            label="New Password"
            variant="filled"
            required
            type="password"
            value={newpassword}
            onChange={(e) => setNewPassword(e.target.value)}
            sx={{ mt: 2, width: isMobile ? "70%" : "50%" }}
          />
          <TextField
            label="Confirm Password"
            variant="filled"
            required
            type="password"
            value={confirmpassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            sx={{ mt: 2, width: isMobile ? "70%" : "50%" }}
          />
          <Button
            type="submit"
            variant="outlined"
            onClick={change_execute}
            sx={{ mt: 2 }}
          >
            Change
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ChangePassword;
