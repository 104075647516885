// import { AppbarHeader } from "../../styles/appbar";
import {
  Box,
  Button,
  //   Divider,
  //   Input,
  //   ListItemButton,
  //   ListItemIcon,
  //   ListItemText,
  TextField,
  AppBar,
  Toolbar,
  IconButton,
  //   Menu,
  //   MenuItem,
  Typography,
  //   Modal,
  Popper,
  Paper,
  ClickAwayListener,
} from "@mui/material";
import { AppbarContainer } from "../../styles/appbar";
// import { AppbarContainer, AppbarHeader, MyList } from "../../styles/appbar";
import SearchIcon from "@mui/icons-material/Search";
// import Actions from "./actions";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PersonIcon from "@mui/icons-material/Person";
// import MailIcon from '@mui/icons-material/Mail'
// import { ListItemText } from "@mui/material"
import { Link } from "react-router-dom";
import Badge from "@mui/material/Badge";
import { useSelector } from "react-redux";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { updateLogin } from "../../actions";
import { url } from "../../url";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import LogoutIcon from "@mui/icons-material/Logout";
// import MenuIcon from "@mui/icons-material/Menu";
import { useMediaQuery } from "react-responsive";
// import { grey } from "@mui/material/colors";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import EmailIcon from "@mui/icons-material/Email";
import { Call } from "@mui/icons-material";
// import { Call, Cookie } from "@mui/icons-material";
import { initializeCart } from "../../actions";

export default function AppbarDesktop() {
  //   const cartItemCount = 5;
  const cart = useSelector((state) => state.cart);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchItem, setSearchItem] = useState("");
  const isMobile = useMediaQuery({ maxWidth: 600 });

  //   const [anchorMenu, setAnchorMenu] = useState(null);
  //   const [anchorSearch, setAnchorSearch] = useState(null);
  const [open, setOpen] = useState(false);
  const imageRef = useRef(null);
  const [anchor, setAnchor] = useState(null);

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetch(url("/b/cart/"), {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.status === 403) {
          Cookies.set("islogin", "false", { path: "/" });
          Cookies.remove("csrftoken");
          navigate("/auth");
        }
        if (response.status === 404) {
          navigate("/main");
        }
        return response.json();
      })
      .then((res) => {
        // const data = [];
        // res.cart.map((item) => {
        //   // console.log(item);
        //   data.push({ product: item, quantity: item.quantity });
        // });
        const data = res.cart.map((item) => {
          return { product: item, quantity: item.quantity };
        });
        // console.log(data)
        var action = initializeCart(data);
        dispatch(action);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [dispatch, navigate]);

  const handleOpen = () => {
    setAnchor(imageRef.current);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickAway = (event) => {
    if (imageRef.current === event.target) {
      return;
    }
    setOpen(false);
  };

  const handleSearchKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch(event);
    }
  };
  const handleSearch = (event) => {
    const searchQuery = event.target.value;
    if (searchQuery !== "") {
      navigate("/productlist/" + searchQuery);
    }
    // console.log('Search query:', searchQuery);
  };

  const handleCartClick = () => {
    navigate("/shoppingcart");
    // console.log('Cart button clicked');
  };

  const handleProfileClick = () => {
    navigate("/account");
    // console.log('Profile button clicked');
  };

  const handleLogoutClick = () => {
    handleClick_logout();
    // console.log('Logout button clicked');
  };

  const handlesearchclick = () => {
    if (searchItem !== "") {
      navigate("/productlist/" + searchItem);
    }
  };

  const handleClick_logout = () => {
    fetch(url("/b/customer/logout/"), {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CSRFToken": Cookies.get("token"),
      },
    })
      .then((res) => res.json())
      .then((res) => {
        Cookies.set("islogin", "false", { path: "/" });
        Cookies.remove("csrftoken");
        const action = updateLogin(false);
        dispatch(action);
        navigate("/auth");
      });
  };
  // console.log(searchItem)
  return (
    <Box>
      {isMobile ? (
        <Box>
          <AppBar position="static">
            <Toolbar>
              <Link
                to={{
                  pathname: "/main",
                }}
                sx={{ flexGrow: 1 }}
              >
                <div>
                  <img
                    alt="logo"
                    style={{ width: 120, height: 40, marginTop: 10 }}
                    className="image"
                    src="https://imagelenwell.s3.us-east-2.amazonaws.com/logo/Lenwell-Digital-Logo-No-BG.png"
                  />
                </div>
              </Link>

              <Box sx={{ flexGrow: 1 }}></Box>

              {/* <Link to="/shoppingcart"> */}

              <IconButton
                color="inherit"
                aria-label="cart"
                onClick={handleCartClick}
              >
                <Badge
                  badgeContent={cart.reduce(
                    (total, item) => total + item.quantity,
                    0
                  )}
                  sx={{ color: "red" }}
                >
                  <ShoppingCartIcon sx={{ color: "red" }} />
                </Badge>
              </IconButton>

              {/* </Link> */}
              {/* <Link to="/account"> */}
              <IconButton
                // color="red"
                aria-label="profile"
                onClick={handleProfileClick}
              >
                <PersonIcon sx={{ color: "red" }} />
              </IconButton>
              {/* </Link> */}
              <IconButton
                color="inherit"
                aria-label="logout"
                onClick={handleLogoutClick}
              >
                <LogoutIcon sx={{ color: "red" }} />
              </IconButton>

              <Call ref={imageRef} onClick={handleOpen} sx={{ color: "red" }} />
              <ClickAwayListener onClickAway={handleClickAway}>
                <Popper open={open} onClose={handleClose} anchorEl={anchor}>
                  <Paper sx={{ padding: "10px" }}>
                    <div className="modal-container">
                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <LocationOnIcon sx={{ marginRight: "20px" }} />
                        <Typography variant="h6"> </Typography>
                        <Typography variant="h6">
                          {" "}
                          5750 Bintliff Dr #210, Houston, TX 77036
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <AccessTimeIcon sx={{ marginRight: "20px" }} />
                        <Box>
                          <Typography variant="h6">
                            {" "}
                            Bussiness Hours:
                          </Typography>
                          <Typography variant="h6">
                            {" "}
                            Monday - Friday: 10:00 - 19:00
                          </Typography>
                          <Typography variant="h6">
                            {" "}
                            Staturday: 10:00 - 19:00
                          </Typography>
                          <Typography variant="h6"> Sunday: Closed</Typography>
                        </Box>
                      </Box>
                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <LocalPhoneIcon sx={{ marginRight: "20px" }} />
                        <Typography variant="h6"> </Typography>
                        <Typography variant="h6"> +1 (832) 275-5999</Typography>
                      </Box>
                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <EmailIcon sx={{ marginRight: "20px" }} />
                        <Typography variant="h6"> </Typography>
                        <Typography variant="h6">
                          {" "}
                          info@lenwellcorp.com
                        </Typography>
                      </Box>
                    </div>
                  </Paper>
                </Popper>
              </ClickAwayListener>
            </Toolbar>
          </AppBar>
        </Box>
      ) : (
        <AppbarContainer
          sx={{ marginLeft: 20, marginRight: 20, marginBottom: 1 }}
        >
          <Link
            to={{
              pathname: "/main",
            }}
          >
            <div>
              <img
                alt="logo"
                style={{ width: 120, height: 40, marginTop: 10 }}
                className="image"
                src="https://imagelenwell.s3.us-east-2.amazonaws.com/logo/Lenwell-Digital-Logo-No-BG.png"
              />
            </div>
          </Link>

          <Box
            className="headerInputContainer"
            style={{ flexGrow: 5, paddingRight: "5px", height: "50px" }}
          >
            <TextField
              disabled
              className="headerInput"
              variant="outlined"
              type="text"
              // placeholder="search Items or Products"
              placeholder="Currently Unavailable"
              style={{ width: "100%", height: "50px" }}
              onKeyPress={handleSearchKeyPress}
              onChange={(e) => {
                setSearchItem(e.target.value);
              }}
            />
            {/* <SearchIcon /> */}
          </Box>

          <Box style={{ marginRight: "50px" }}>
            <Button
              onClick={() => {
                handlesearchclick();
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <SearchIcon style={{ color: "red", fontSize: "350%" }} />
                <Typography style={{ color: "grey" }}>Search</Typography>
              </Box>
            </Button>
          </Box>

          <Box style={{ marginRight: "10px" }}>
            <Button
              onClick={() => {
                handleCartClick();
              }}
              sx={{ width: "100px" }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Badge
                  badgeContent={cart.reduce(
                    (total, item) => total + item.quantity,
                    0
                  )}
                  sx={{ color: "red" }}
                >
                  <ShoppingCartIcon
                    style={{ color: "red", fontSize: "350%" }}
                  />

                  {/* <MailIcon color="action" /> */}
                </Badge>
                <Typography style={{ color: "grey" }}>Cart</Typography>
              </Box>
            </Button>
          </Box>

          <Box style={{ marginRight: "10px" }}>
            <Link
              to={{
                pathname: "/account/dashboard/",
              }}
              style={{ textDecoration: "none" }}
            >
              <Button>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <PersonIcon style={{ color: "red", fontSize: "350%" }} />
                  <Typography style={{ color: "grey" }}>Account</Typography>
                </Box>
              </Button>
            </Link>
          </Box>

          <Box style={{ marginRight: "10px" }}>
            <Button
              onClick={() => {
                handleClick_logout();
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <LogoutIcon style={{ color: "red", fontSize: "350%" }} />
                <Typography style={{ color: "grey" }}>Logout</Typography>
              </Box>
            </Button>
          </Box>
          <div>
            <img
              alt="contact us"
              style={{
                width: 120,
                height: 40,
                marginTop: 10,
                cursor: "pointer",
              }}
              className="image"
              src="https://imagelenwell.s3.us-east-2.amazonaws.com/logo/contact_us.png"
              onClick={handleOpen}
              ref={imageRef}
            />
          </div>
          <ClickAwayListener onClickAway={handleClickAway}>
            <Popper open={open} onClose={handleClose} anchorEl={anchor}>
              <Paper sx={{ padding: "10px" }}>
                <div className="modal-container">
                  {/* <Typography variant="h6">联系方式:</Typography>
                            <Typography variant="body1">电话: 123456789</Typography>
                            <Typography variant="body1">邮箱: example@example.com</Typography> */}
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <LocationOnIcon sx={{ marginRight: "20px" }} />
                    <Typography variant="h6"> </Typography>
                    <Typography variant="h6">
                      {" "}
                      5750 Bintliff Dr #210, Houston, TX 77036
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <AccessTimeIcon sx={{ marginRight: "20px" }} />
                    <Box>
                      <Typography variant="h6"> Bussiness Hours:</Typography>
                      <Typography variant="h6">
                        {" "}
                        Monday - Friday: 10:00 - 19:00
                      </Typography>
                      <Typography variant="h6">
                        {" "}
                        Staturday: 10:00 - 19:00
                      </Typography>
                      <Typography variant="h6"> Sunday: Closed</Typography>
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <LocalPhoneIcon sx={{ marginRight: "20px" }} />
                    <Typography variant="h6"> </Typography>
                    <Typography variant="h6"> +1 (832) 275-5999</Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <EmailIcon sx={{ marginRight: "20px" }} />
                    <Typography variant="h6"> </Typography>
                    <Typography variant="h6"> info@lenwellcorp.com</Typography>
                  </Box>
                </div>
              </Paper>
            </Popper>
          </ClickAwayListener>
        </AppbarContainer>
      )}
    </Box>
  );
}
