// export const url = "http://localhost:8000/";

// export const url= path => `http://localhost:8000${path}`;//implement string combination using anonymous functions

// export var url= path => `https://backend.guanchengonline.com${path}`;

// export var url= path => `https://testbackend.guanchengonline.com${path}`;

// export var url= path => `https://system.lenwellscreen.com${path}`;

export var url = (path) => `https://system.lenwelldigital.com${path}`;

if (process.env.NODE_ENV === "development") {
  url = (path) => `http://localhost:8000${path}`;
}
