import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Typography,
  CardMedia,
  Button,
  Box,
  Divider,
  Snackbar,
  SnackbarContent,
  Alert,
  CardHeader,
  CardContent,
  Card,
} from "@mui/material";
import AppbarDesktop from "../appbar/appbarDesktop";
import Menu from "../menu/menu";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addToCart } from "../../actions";
import { useMediaQuery } from "react-responsive";
import MobileMenu from "../menu/mobile_menu";
import { url } from "../../url";
import Cookies from "js-cookie";

// const useStyles = styled((theme) => ({
//   container: {
//     paddingTop: theme.spacing(4),
//     paddingBottom: theme.spacing(4),
//   },
// }));

const ProductDetailPage = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 600 });
  var current_product = useSelector((state) => state.current_product);
  const [current_single_product, set_Current_single_product] =
    useState(current_product);
  const [notification, setNotification] = useState("");
  const { productId } = useParams();

  useEffect(() => {
    fetch(url("/b/inventory/" + productId + ""), { credentials: "include" })
      .then((res) => {
        if (res.status === 403) {
          Cookies.set("islogin", "false", { path: "/" });
          Cookies.remove("token");
          navigate("/auth");
        }
        if (res.status === 404) {
          navigate("/main");
        }
        return res.json();
      })
      .then((res) => {
        set_Current_single_product({ product: res.item, quantity: 1 });
        document.title = "Lenwell | " + res.item.product_name;
      });
  }, [productId, navigate]);

  const handleIncrement = () => {
    const updateDictionary = {
      ...current_single_product,
      quantity: current_single_product.quantity + 1,
    };
    set_Current_single_product(updateDictionary);
  };

  const handleDecrement = () => {
    if (current_single_product.quantity > 1) {
      const updateDictionary = {
        ...current_single_product,
        quantity: current_single_product.quantity - 1,
      };
      set_Current_single_product(updateDictionary);
    }
  };

  const handleClick = (product) => {
    var amount = current_single_product.quantity;
    const updataDictionary = { ...current_single_product, quantity: 1 };
    set_Current_single_product(updataDictionary);
    if (amount > 0) {
      setNotification("Item added to cart");
    }
    var formData = {
      uuid: product.uuid,
      quantity: amount,
    };

    fetch(url("/b/cart/add/"), {
      method: "POST",
      credentials: "include",
      headers: { "X-CSRFToken": Cookies.get("token") },
      body: JSON.stringify(formData),
    })
      .then((res) => {
        if (res.status === 403) {
          Cookies.set("islogin", "false", { path: "/" });
          Cookies.remove("token");
          navigate("/auth");
        }
        if (res.status === 404) {
          navigate("/main");
        }
        return res.json();
      })
      .then((res) => {
        set_Current_single_product(updataDictionary);
      });

    const action = addToCart(product, amount);
    dispatch(action);
  };

  const handleCloseNotification = () => {
    setNotification("");
  };

  if (Object.keys(current_single_product).length !== 0) {
    return (
      <Box>
        {isMobile ? (
          <Box>
            <AppbarDesktop />
            <MobileMenu />
            <Box sx={{ marginLeft: 2, marginRight: 2, marginBottom: "60px" }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <CardMedia
                    component="img"
                    height="400"
                    image={current_single_product.product.product_url_list[0]}
                    alt={current_single_product.product.product_name}
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Typography variant="h5" component="h1">
                    {current_single_product.product.product_name}
                  </Typography>

                  <Typography variant="h6" component="h2">
                    Description
                  </Typography>
                  <Typography variant="body1" component="div">
                    {current_single_product.product.description_list.map(
                      (item, index) => (
                        <Typography key={"desc " + index}>{item}</Typography>
                      )
                    )}
                  </Typography>

                  <Typography variant="h6" component="div">
                    Price: ${current_single_product.product.price_b}
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        handleDecrement();
                      }}
                    >
                      -
                    </Button>
                    <Typography
                      sx={{
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        paddingTop: "6px",
                      }}
                    >
                      {current_single_product.quantity}
                    </Typography>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        handleIncrement();
                      }}
                    >
                      +
                    </Button>
                  </Box>

                  <Divider sx={{ paddingTop: "5px" }} />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        handleClick(current_single_product.product);
                      }}
                      sx={{ marginTop: "5px" }}
                    >
                      Add to Cart
                    </Button>
                    <Snackbar
                      open={Boolean(notification)}
                      autoHideDuration={3000}
                      onClose={handleCloseNotification}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      style={{ marginTop: "40px" }} // Adjust the marginTop value to move the Snackbar higher
                    >
                      <SnackbarContent
                        sx={{
                          backgroundColor: "#ffcc00", // Customize the background color here
                        }}
                        message={
                          <Alert
                            onClose={handleCloseNotification}
                            severity="success"
                          >
                            {notification}
                          </Alert>
                        }
                      />
                    </Snackbar>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        ) : (
          <Box>
            <AppbarDesktop />
            <Menu />
            <Divider />

            <Container maxWidth="lg">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <CardMedia
                    component="img"
                    height="400"
                    image={current_single_product.product.product_url_list[0]}
                    alt={current_single_product.product.product_name}
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Typography
                    variant="h4"
                    component="h1"
                    sx={{ marginTop: "20px" }}
                  >
                    {current_single_product.product.product_name}
                  </Typography>

                  <Card sx={{ marginTop: "30px" }}>
                    <CardHeader
                      title={"Product Description"}
                      style={{ backgroundColor: "#757de8", color: "white" }}
                    />
                    <CardContent>
                      <Typography variant="body1" component="div">
                        {current_single_product.product.description_list.map(
                          (item, index) => (
                            <Typography key={"desc " + index}>
                              {item}
                            </Typography>
                          )
                        )}
                      </Typography>
                    </CardContent>
                  </Card>

                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ marginTop: "30px" }}
                  >
                    Price: ${current_single_product.product.price_b}
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      marginTop: "20px",
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        handleDecrement();
                      }}
                    >
                      -
                    </Button>
                    <Typography
                      sx={{
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        paddingTop: "6px",
                      }}
                    >
                      {current_single_product.quantity}
                    </Typography>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        handleIncrement();
                      }}
                    >
                      +
                    </Button>
                  </Box>

                  <Divider sx={{ paddingTop: "5px" }} />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        handleClick(current_single_product.product);
                      }}
                      sx={{ marginTop: "5px", marginBottom: "10px" }}
                    >
                      Add to Cart
                    </Button>
                    <Snackbar
                      open={Boolean(notification)}
                      autoHideDuration={3000}
                      onClose={handleCloseNotification}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      style={{ marginTop: "40px" }} // Adjust the marginTop value to move the Snackbar higher
                    >
                      <SnackbarContent
                        sx={{
                          backgroundColor: "#ffcc00", // Customize the background color here
                        }}
                        message={
                          <Alert
                            onClose={handleCloseNotification}
                            severity="success"
                          >
                            {notification}
                          </Alert>
                        }
                      />
                    </Snackbar>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
        )}
      </Box>
    );
  } else {
    return <Box></Box>;
  }
};

export default ProductDetailPage;
