import React from "react";
// import ReactDOM from "react-dom/client";//v18
import ReactDOM from "react-dom"; //v17
import "./index.css";
import App from "./App";
// import { configureStore } from '@reduxjs/toolkit'
// import { tictactoeApp } from "./reducers";
// import store from './app/store';
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import { frontend_tob } from "./reducers";
import { checkLoginStatus, checkToken } from "./actions";
import thunk from "redux-thunk";
let store = createStore(frontend_tob, applyMiddleware(thunk));

store.dispatch(checkLoginStatus()); // execute before reducer, important
store.dispatch(checkToken());
//react v18
// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     <Provider store={store}>
//     <App/>
//   </Provider>
//     </React.StrictMode>
// );

//react v17
ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>,
  // </React.StrictMode>
  document.getElementById("root")
);
