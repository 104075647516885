const initialState = {
  islogin: false,
  product_list: [],
  totalItems: 0,
  cart: [],
  current_product: {},
  uuid: null,
  token: "",
};

export function frontend_tob(state = initialState, action) {
  switch (action.type) {
    case "SET_TOKEN":
      return {
        ...state,
        token: action.payload,
      };
    case "SET_UUID":
      return {
        ...state,
        uuid: action.payload,
      };
    case "UPDATE_DATA":
      return {
        ...state,
        product_list: [...state.product_list, action.payload],
      };

    // case 'UPDATE_QUANTITY':
    //     const { productId, quantity } = action.payload; //be able to get more arguments than two
    //     const updatedProducts = state.product_list.map((product) => {
    //         if (product.id === productId) {
    //             return { ...product, quantity };
    //         }
    //         return product;
    //     });
    //     return {
    //         ...state,
    //         product_list: updatedProducts,
    //     };

    case "CHECK_LOGIN_STATUS":
      return {
        ...state,
        islogin: action.payload,
      };

    case "UPDATE_LOGIN":
      return { ...state, islogin: action.payload };

    case "UPDATE_CURRENT_PRODUCT":
      return { ...state, current_product: action.payload };

    case "INITIALIZE_CART":
      // console.log(action.payload.cart)
      return { ...state, cart: action.payload.cart };

    case "ADD_TO_CART":
      const { product, quantity } = action.payload;
      const existingProduct = state.cart.find(
        (item) => item.product.uuid === product.uuid
      );
      // console.log("test")
      if (existingProduct) {
        // update its quantity if item already exists in the cart.
        // console.log("test3")
        const updatedCart = state.cart.map((item) => {
          if (item.product.uuid === product.uuid) {
            return {
              ...item,
              quantity: item.quantity + quantity,
            };
          }
          return item;
        });

        return {
          ...state,
          cart: updatedCart,
          totalItems: state.totalItems + quantity,
        };
      } else {
        //
        // console.log("test3")
        return {
          ...state,
          cart: [...state.cart, { product, quantity }],
          totalItems: state.totalItems + quantity,
        };
      }

    case "UPDATE_TO_CART":
      const { update_product, update_quantity } = action.payload;
      const update_existingProduct = state.cart.find(
        (item) => item.product.uuid === update_product.uuid
      );
      var prev_account;
      var distance;
      if (update_existingProduct) {
        // update its quantity, if the item already exists in the cart.
        const updatedCart = state.cart.map((item) => {
          if (item.product.uuid === update_product.uuid) {
            prev_account = item.quantity;
            distance = update_quantity - prev_account;
            return {
              ...item,
              quantity: update_quantity,
            };
          }
          return item;
        });

        return {
          ...state,
          cart: updatedCart,
          totalItems: state.totalItems + distance,
        };
      }
      return state;
    case "REMOVE_FROM_CART":
      const productId = action.payload;
      const updatedCart = state.cart.filter(
        (item) => item.product.uuid !== productId
      );
      const removedItem = state.cart.find(
        (item) => item.product.uuid === productId
      );
      console.log(productId);
      return {
        ...state,
        cart: updatedCart,
        totalItems: state.totalItems - removedItem.quantity,
      };
    case "CHANGE_B":
      return { ...state, b: action.payload };
    case "change_login_state":
      return { ...state, b: action.login_state };
    default:
      return state;
  }
}
