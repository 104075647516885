// import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";
// import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
// import TextField from "@mui/material/TextField";
// import Box from "@mui/material/Box";
// import axios from "axios";
import Login from "./login/login.component";
import Registration from "./registration/registration.component";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import {connect} from "react-redux";
// import { styled } from '@mui/material/styles';
// import Paper from "@mui/material/Paper";
// import { connect } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

// const url = "https://jsonplaceholder.typicode.com/users";
// const postsUrl = "https://jsonplaceholder.typicode.com/posts";

function Auth(props) {
  const isMobile = useMediaQuery({ maxWidth: 600 });
  useEffect(() => {
    document.title = "Lenwell | Auth"; //
  }, []);
  return (
    <div>
      {isMobile ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* <Registration /> */}
          <Box
            sx={{
              marginTop: "100px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" marginBottom={5}>
              Sign In
            </Typography>
            <Login />
            <Box marginTop={5}>
              <Link to="/mobileregister">Don't have an account? Sign Up</Link>
            </Box>
          </Box>
        </Box>
      ) : (
        <Grid
          container
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt="5px"
          mb="50px"
          spacing={2}
          align="center"
        >
          <Grid item xs={12}>
            <Card sx={{ width: 700, padding: 2, fontSize: 30, opacity: 0.7 }}>
              Lenwell
            </Card>
          </Grid>
          <Grid item zIndex={9999}>
            <Registration />
          </Grid>
          <Grid item zIndex={0}>
            <Login />
          </Grid>
        </Grid>
      )}
    </div>
  );
}

// export default Auth;
export default Auth;
