import React, { useState, useEffect } from "react";
import { Snackbar, Box, SnackbarContent } from "@mui/material";
// import { Snackbar, Typography, Box, SnackbarContent } from "@mui/material";
// import { Alert } from "@mui/material";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const CookiePrompt = () => {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  //   const handleAccept = () => {
  //     //to do
  //     setOpen(false);
  //   };

  //   const handleDecline = () => {
  //     //to do
  //     setOpen(false);
  //   };

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={() => {
        setOpen(false);
      }}
    >
      <SnackbarContent
        message={
          "This website uses cookies. By using this site, you agree to our use of cookies."
        }
        action={
          <React.Fragment>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {/* <Button
                // color="secondary"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                Accept
              </Button> */}
              <Button
                // variant="text"
                // color="secondary"
                onClick={() => {
                  navigate("/privacy");
                }}
              >
                Learn More
              </Button>
            </Box>
          </React.Fragment>
        }
      />
      {/* <Alert severity="info"  > */}
      {/* <Box  sx={{display:"flex",flexDirection:"row"}}>
                <Typography sx={{}}> This website uses cookies. By using this site, you agree to our use of cookies. </Typography>
                <Box sx={{marginLeft:"20px", marginTop:"0px"}}>
                <Button onClick={()=>{setOpen(false)}}>Accept</Button>
                <Typography>learn more</Typography>
                </Box>
                </Box> */}
      {/* This website uses cookies to enhance your experience. */}
      {/* Please read our cookie policy for more information. */}

      {/* </Alert> */}
      {/* <Button>Accept</Button> */}
    </Snackbar>
  );
};
export default CookiePrompt;
