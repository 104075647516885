import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AppbarDesktop from "../main/appbar";
import { Box } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import LeftMenu from "./left_menu/menu";
import MobileMenu from "../main/menu/mobile_menu";
import Cookies from "js-cookie";
import { url } from "../url";
import { updateLogin } from "../actions";
import { Navigate, useNavigate } from "react-router-dom";
import Dashboard from "./content/Dashboard";

const Profile = () => {
  const islogin = useSelector((state) => state.islogin);
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userInfor, setUserInfor] = useState(null);

  useEffect(() => {
    document.title = "Lenwell | Profile"; //
  }, []);

  useEffect(() => {
    //detect data, rerender when data is changed.
    fetch(url("/b/customer/profile/"), {
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 403) {
          Cookies.set("islogin", "false", { path: "/" });
          Cookies.remove("csrftoken");
          const action = updateLogin(false);
          dispatch(action);
          navigate("/auth");
        }
        if (response.status === 404) {
          navigate("/main");
        }
        return response.json();
      })
      .then((data) => {
        setUserInfor(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [dispatch, navigate]);

  if (islogin === false) {
    return <Navigate to="/" />;
  }

  if (userInfor === null) {
    return (
      <Box>
        <AppbarDesktop />
      </Box>
    );
  } else {
  }

  return (
    <Box>
      {isMobile ? (
        <Box>
          <AppbarDesktop />
          <MobileMenu sx={{ flexGrow: 1 }} />
          <LeftMenu />
        </Box>
      ) : (
        <Dashboard />
      )}
    </Box>
  );
};

export default Profile;
