import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  Button,
  Divider,
  Snackbar,
  SnackbarContent,
  Alert,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addToCart, updateCurretnProduct } from "../../actions";
import { useMediaQuery } from "react-responsive";
import Cookies from "js-cookie";
import { url } from "../../url";

const ServiceList = (data) => {
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const dispatch = useDispatch();
  var service_list = data.service_list.services;
  var cur_page_service_count = {};
  const [current_page_services, set_Current_page_services] = useState(
    cur_page_service_count
  );
  const [notification, setNotification] = useState("");
  const navigate = useNavigate();

  const update_count = () => {
    cur_page_service_count = {};
    if (service_list !== undefined) {
      service_list.forEach((element) => {
        cur_page_service_count[element.uuid] = 1;
      });
    }
    set_Current_page_services(cur_page_service_count);
  };

  useEffect(() => {
    //detect data, rerender when data is changed.
    update_count();
  }, [service_list]);

  const handleIncrement = (service) => {
    var uuid = service.uuid;
    const updataDictionary = {
      ...current_page_services,
      [uuid]: current_page_services[service.uuid] + 1,
    };
    set_Current_page_services(updataDictionary);
  };

  const handleDecrement = (service) => {
    var uuid = service.uuid;
    if (current_page_services[service.uuid] > 1) {
      const updataDictionary = {
        ...current_page_services,
        [uuid]: current_page_services[service.uuid] - 1,
      };
      set_Current_page_services(updataDictionary);
    }
  };

  const handleClick = (service) => {
    var amount = current_page_services[service.uuid];
    const updataDictionary = { ...current_page_services, [service.uuid]: 1 };
    set_Current_page_services(updataDictionary);
    var formData = {
      uuid: service.uuid,
      quantity: amount,
    };
    fetch(url("/b/cart/add/"), {
      method: "POST",
      credentials: "include",
      headers: { "X-CSRFToken": Cookies.get("token") },
      body: JSON.stringify(formData),
    })
      .then((res) => {
        if (res.status === 403) {
          Cookies.set("islogin", "false", { path: "/" });
          Cookies.remove("csrftoken");
          navigate("/auth");
        }
        if (res.status === 404) {
          navigate("/main");
        }
        return res.json();
      })
      .then((res) => {
        set_Current_page_services(updataDictionary);
        const action = addToCart(service, amount);
        dispatch(action);
      });
  };

  const handleAddToCart = (serviceId) => {
    if (current_page_services[serviceId.uuid] > 0) {
      setNotification("Item added to cart");
    }
    handleClick(serviceId);
  };

  const handleCloseNotification = () => {
    setNotification("");
  };

  const handLinkleClick = (item) => {
    const action = updateCurretnProduct(item);
    dispatch(action);
  };

  //condition render
  if (service_list !== undefined && service_list.length !== 0) {
    return (
      <Box>
        {isMobile ? (
          <Box>
            <Grid container spacing={2}>
              {service_list.map((product) => (
                <Grid item key={product.id} xs={6}>
                  <Card key={product.id}>
                    <Link
                      key={product.id}
                      to={{ pathname: `/products/${product.uuid}` }}
                    >
                      <CardMedia
                        key={product.id}
                        component="img"
                        height="200"
                        image={product.product_url_list[0]}
                        alt={product.product_name}
                        onClick={handLinkleClick({ product, quantity: 0 })} //automatically execute
                        sx={{
                          height: "100%",
                          display: "block",
                          overflow: "hidden",
                          width: "100%",
                        }}
                      />
                    </Link>
                    <CardContent>
                      <Box sx={{ height: "60px" }}>
                        {" "}
                        <Typography
                          variant="h9"
                          style={{ fontWeight: "700", fontSize: "12px" }}
                        >
                          {product.product_name}
                        </Typography>
                      </Box>

                      <Typography variant="subtitle1" color="textPrimary">
                        Price: ${product.price_b}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleDecrement(product)}
                        >
                          -
                        </Button>
                        <Typography
                          id={product.id}
                          sx={{
                            paddingLeft: "20px",
                            paddingRight: "20px",
                            paddingTop: "6px",
                          }}
                        >
                          {current_page_services[product.uuid]}
                        </Typography>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleIncrement(product)}
                        >
                          +
                        </Button>
                      </Box>

                      <Divider sx={{ paddingTop: "5px" }} />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleAddToCart(product)}
                          sx={{ marginTop: "5px" }}
                        >
                          Add to Cart
                        </Button>
                        <Snackbar
                          open={Boolean(notification)}
                          autoHideDuration={3000}
                          onClose={handleCloseNotification}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          style={{ marginTop: "40px" }} // Adjust the marginTop value to move the Snackbar higher
                        >
                          <SnackbarContent
                            sx={{
                              backgroundColor: "#ffcc00", // Customize the background color here
                            }}
                            message={
                              <Alert
                                onClose={handleCloseNotification}
                                severity="success"
                              >
                                {notification}
                              </Alert>
                            }
                          />
                        </Snackbar>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        ) : (
          <Box sx={{ marginLeft: 20, marginRight: 20 }}>
            <Grid container spacing={2}>
              {service_list.map((product) => (
                <Grid item key={product.id} xs={12} sm={6} md={4} lg={2.5}>
                  <Card key={product.id}>
                    <Link
                      key={product.id}
                      to={{ pathname: `/products/${product.uuid}` }}
                    >
                      <CardMedia
                        key={product.id}
                        component="img"
                        height="200"
                        image={product.product_url_list[0]}
                        alt={product.product_name}
                        onClick={handLinkleClick({ product, quantity: 0 })} //automatically execute
                        sx={{
                          height: "100%",
                          display: "block",
                          overflow: "hidden",
                          width: "100%",
                        }}
                      />
                      {/* </Box> */}
                    </Link>
                    <CardContent>
                      <Box sx={{ height: "60px" }}>
                        <Typography
                          variant="h9"
                          style={{ fontWeight: "700", fontSize: "12px" }}
                        >
                          {product.product_name}
                        </Typography>
                      </Box>

                      <Typography variant="subtitle1" color="textPrimary">
                        Price: ${product.price_b}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleDecrement(product)}
                        >
                          -
                        </Button>
                        <Typography
                          id={product.id}
                          sx={{
                            paddingLeft: "20px",
                            paddingRight: "20px",
                            paddingTop: "6px",
                          }}
                        >
                          {current_page_services[product.uuid]}
                        </Typography>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleIncrement(product)}
                        >
                          +
                        </Button>
                      </Box>

                      <Divider sx={{ paddingTop: "5px" }} />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleAddToCart(product)}
                          sx={{ marginTop: "5px" }}
                        >
                          Add to Cart
                        </Button>
                        <Snackbar
                          open={Boolean(notification)}
                          autoHideDuration={3000}
                          onClose={handleCloseNotification}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          style={{ marginTop: "40px" }} // Adjust the marginTop value to move the Snackbar higher
                        >
                          <SnackbarContent
                            sx={{
                              backgroundColor: "#ffcc00", // Customize the background color here
                            }}
                            message={
                              <Alert
                                onClose={handleCloseNotification}
                                severity="success"
                              >
                                {notification}
                              </Alert>
                            }
                          />
                        </Snackbar>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </Box>
    );
  } else {
    return (
      <Box>
        {isMobile ? (
          <Typography variant="h6" style={{ marginLeft: "16px" }}>
            Coming soon ...
          </Typography>
        ) : (
          <Typography variant="h6" style={{ marginLeft: "160px" }}>
            Coming soon ...
          </Typography>
        )}
      </Box>
    );
  }
};

export default ServiceList;
