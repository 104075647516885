import React, { useState, useEffect } from "react";
import { Box, Divider, Typography } from "@mui/material";
// import DeleteIcon from '@mui/icons-material/Delete';
// import { Link } from 'react-router-dom';
// import { useSelector } from 'react-redux';
import AppbarDesktop from "../../main/appbar/appbarDesktop";
import Menu from "../menu/menu";
// import { removeFromCart, addToCart, updateToCart } from '../../actions';
// import { useDispatch } from 'react-redux';
// import { act } from 'react-dom/test-utils';
import ProductList from "./product_list";
import ServiceList from "./service_list";
import { useNavigate, useParams } from "react-router-dom";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
// import { Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateLogin } from "../../actions";
import Cookies from "js-cookie";
import { url } from "../../url";
import { useMediaQuery } from "react-responsive";
import MobileMenu from "../menu/mobile_menu";

const ProductCompleteListPage = () => {
  const { productName } = useParams(); // must match url params name
  // console.log(productName)
  const [data, setData] = useState(null);
  const [data2, setData2] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 600 });

  useEffect(() => {
    document.title = "Lenwell | " + productName; //
    // window.location.reload()
  }, [productName]); //In order to prevent the phenomenon that the page will not be refreshed when entering this page again

  useEffect(() => {
    //detect data, rerender when data is changed.
    fetch(url("/b/inventory/search/?keyword=" + productName), {
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 403) {
          Cookies.set("islogin", "false", { path: "/" });
          Cookies.remove("csrftoken");
          const action = updateLogin(false);
          dispatch(action);
          navigate("/auth");
        }
        if (response.status === 404) {
          navigate("/");
        }

        return response.json();
      })
      .then((data) => {
        // console.log("data1= ",data);
        setData(data);
        setData2(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [productName, dispatch, navigate]);

  if (data !== null && data2 != null) {
    // because of multiple renders, so there is null situation.
    return (
      <Box>
        {isMobile ? (
          <Box>
            <AppbarDesktop />
            <MobileMenu />
            <Divider />
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "16px",
              }}
            >
              {" "}
              <FormatListBulletedIcon
                style={{ marginTop: "10px", color: "red" }}
              />
              <Typography variant="h4" style={{ marginLeft: "8px" }}>
                Repair Services
              </Typography>
            </Box>
            <ServiceList service_list={data2} />
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "16px",
              }}
            >
              {" "}
              <FormatListBulletedIcon
                style={{ marginTop: "10px", color: "red" }}
              />
              <Typography variant="h4" style={{ marginLeft: "8px" }}>
                Items for Sale
              </Typography>
            </Box>
            <ProductList products={data.products} />
          </Box>
        ) : (
          <div>
            <AppbarDesktop />
            <Menu />
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "160px",
              }}
            >
              {" "}
              <FormatListBulletedIcon
                style={{ marginTop: "10px", color: "red" }}
              />
              <Typography variant="h4" style={{ marginLeft: "8px" }}>
                Repair Services
              </Typography>
            </Box>
            <ServiceList service_list={data2} />
            <Divider
              style={{
                marginLeft: "160px",
                marginRight: "160px",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            />
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "160px",
              }}
            >
              {" "}
              <FormatListBulletedIcon
                style={{ marginTop: "10px", color: "red" }}
              />
              <Typography variant="h4" style={{ marginLeft: "8px" }}>
                Items for Sale
              </Typography>
            </Box>
            <ProductList products={data.products} />
          </div>
        )}
      </Box>
    );
  } else {
    return (
      <Box>
        <AppbarDesktop />
        <Menu />
        {/* <ProductList products={data.products}/> */}
        <Typography variant="h3" style={{ marginLeft: "160px" }}></Typography>
      </Box>
    );
  }
};
export default ProductCompleteListPage;
