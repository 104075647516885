import React, { useEffect } from "react";
import Login from "../login/login.component";
import Registration from "./registration.component";
import { useMediaQuery } from "react-responsive";
import { Card, Grid, Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

function MobileRegistration(props) {
  const isMobile = useMediaQuery({ maxWidth: 600 });

  useEffect(() => {
    document.title = "Lenwell | Auth";
  }, []);

  return (
    <div>
      {isMobile ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              marginTop: "100px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" marginBottom={5}>
              Sign In
            </Typography>
            <Registration />
            <Box marginTop={5} marginBottom={10}>
              <Link to="/auth">Login</Link>
            </Box>
          </Box>
        </Box>
      ) : (
        <Grid
          container
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt="5px"
          mb="50px"
          spacing={2}
          align="center"
        >
          <Grid item xs={12}>
            <Card sx={{ width: 700, padding: 2, fontSize: 30, opacity: 0.7 }}>
              Lenwell
            </Card>
          </Grid>
          <Grid item>
            <Registration />
          </Grid>
          <Grid item>
            <Login />
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export default MobileRegistration;
