import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import AppbarDesktop from "../../main/appbar";
import Menu from "../../main/menu/menu";
import LeftMenu from "../left_menu/menu";
import MobileMenu from "../../main/menu/mobile_menu";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { url } from "../../url";
import Cookies from "js-cookie";
import { updateLogin } from "../../actions";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Paper,
  Grid,
  Pagination,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";

const InvoiceStatus = () => {
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const islogin = useSelector((state) => state.islogin);
  const [invoices, setInvoices] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    fetch(url(`/b/invoice/view_invoices/onsite/?page=${page}`), {
      method: "GET",
      credentials: "include",
      headers: { "X-CSRFToken": Cookies.get("token") },
    })
      .then((response) => {
        if (response.status === 403) {
          Cookies.set("islogin", "false", { path: "/" });
          Cookies.remove("csrftoken");
          const action = updateLogin(false);
          dispatch(action);
          navigate("/auth");
        }
        if (response.status === 404) {
          navigate("/main");
        }
        return response.json();
      })
      .then((data) => {
        setInvoices(data.invoices);
        setTotalPages(data.total_pages);
        // console.log(data.invoices);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [dispatch, navigate, page]);

  if (!islogin) {
    return <Navigate to="/" />;
  }

  return (
    <Box>
      <AppbarDesktop />
      {isMobile ? (
        <Box>
          <MobileMenu sx={{ flexGrow: 1 }} />
          <Link to="/account">return to account menu</Link>
        </Box>
      ) : (
        <Menu />
      )}
      <Box
        sx={{
          marginLeft: !isMobile && "12%",
          marginRight: !isMobile && "10%",
          display: "flex",
          flex: 1,
          alignItems: isMobile ? "center" : "stretch",
          marginTop: isMobile ? 5 : 0,
          flexDirection: isMobile ? "column" : "row",
          paddingBottom: "2%",
        }}
      >
        {!isMobile && (
          <Paper>
            <LeftMenu />
          </Paper>
        )}
        {invoices && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginLeft: !isMobile && "50px",
              width: "90%",
            }}
          >
            {invoices.length === 0 && (
              <Typography>You don't have any existing invoices.</Typography>
            )}
            {/* <Paper sx={{ width: "100%" }}> */}
            {invoices.map((invoice, index) => (
              <Card
                key={invoice.id}
                sx={{
                  minWidth: !isMobile && 450,
                  marginBottom: index < invoices.length - 1 ? "30px" : 0,
                }}
              >
                <CardHeader
                  sx={{
                    backgroundColor: "lightgray",
                    padding: "0 16px 0 16px",
                  }}
                  subheader={
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid
                        item
                        container
                        md={8}
                        sm={8}
                        xs={12}
                        spacing={3}
                        alignItems="center"
                        wrap="nowrap"
                      >
                        <Grid item my={1}>
                          <Typography
                            style={{
                              fontSize: "0.8rem",
                              color: "grey",
                            }}
                          >
                            INVOICE
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "0.8rem",
                              color: "grey",
                              textAlign: "center",
                            }}
                          >
                            # {invoice.id}
                          </Typography>
                        </Grid>
                        <Grid item my={1}>
                          <Typography
                            style={{
                              fontSize: "0.8rem",
                              color: "grey",
                            }}
                          >
                            DATE
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "0.8rem",
                              color: "grey",
                            }}
                          >
                            {invoice.created_at}
                          </Typography>
                        </Grid>
                        <Grid item my={1}>
                          <Typography
                            style={{
                              fontSize: "0.8rem",
                              color: "grey",
                            }}
                          >
                            TOTAL
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "0.8rem",
                              color: "grey",
                            }}
                          >
                            ${invoice.charge}
                          </Typography>
                        </Grid>
                        <Grid item my={1}>
                          <Typography
                            style={{
                              fontSize: "0.8rem",
                              color: "grey",
                              textAlign: "center",
                            }}
                          >
                            STATUS
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "0.8rem",
                              color: "grey",
                              textAlign: "center",
                            }}
                          >
                            {invoice.status}
                          </Typography>
                        </Grid>
                      </Grid>

                      {/* <Grid
                          item
                          container
                          md={4}
                          sm={3}
                          xs={12}
                          my={1}
                          justifyContent="flex-end"
                          alignItems="center"
                        >
                          <Grid item>
                            <Typography
                              style={{
                                fontSize: "0.8rem",
                                color: "grey",
                                textAlign: "right",
                              }}
                            >
                              ORDER # {invoice.id}
                            </Typography>
                            <Link
                              href="#"
                              underline="hover"
                              style={{ color: "teal" }}
                            >
                              <Typography style={{ fontSize: "0.8rem" }}>
                                Track Package
                              </Typography>
                            </Link>
                          </Grid>
                        </Grid> */}
                    </Grid>
                  }
                />
                <CardContent
                  sx={{
                    padding: 0,
                  }}
                >
                  <List>
                    {invoice.item_list.map((item, itemIndex) => (
                      <React.Fragment key={itemIndex}>
                        <ListItem key={itemIndex}>
                          <Grid container>
                            <Grid item xs={12} sm={6}>
                              <ListItemText
                                primary={
                                  <Typography component={"span"}>
                                    {item.product_name}
                                  </Typography>
                                }
                                secondary={
                                  <React.Fragment>
                                    <Typography component={"span"}>
                                      Quantity: {item.quantity}
                                    </Typography>{" "}
                                    <br />
                                    <Typography component={"span"}>
                                      Total: ${item.quantity * item.price}
                                    </Typography>
                                  </React.Fragment>
                                }
                              />
                            </Grid>
                          </Grid>
                        </ListItem>
                        {itemIndex < invoice.item_list.length - 1 && (
                          <Divider />
                        )}
                      </React.Fragment>
                    ))}
                  </List>
                </CardContent>
              </Card>
            ))}
            {/* </Paper> */}
            <Pagination
              size="large"
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              sx={{ marginTop: "20px" }}
            ></Pagination>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default InvoiceStatus;
