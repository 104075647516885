import React, { useEffect, useState } from "react";
import { AddressElement } from "@stripe/react-stripe-js";
import { url } from "../url";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateLogin } from "../actions";
import { Typography } from "@mui/material";

const AddressForm = () => {
  const [userInfo, setuserInfo] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    fetch(url("/b/customer/profile/"), {
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 403) {
          Cookies.set("islogin", "false", { path: "/" });
          Cookies.remove("csrftoken");
          const action = updateLogin(false);
          dispatch(action);
          navigate("/auth");
        }
        if (response.status === 404) {
          navigate("/main");
        }
        return response.json();
      })
      .then((data) => {
        setuserInfo(data);
        // console.log(data);
        //   setImageURL(data.customers.image_url);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [dispatch, navigate]);

  if (userInfo === "") {
    return <Typography></Typography>;
  } else {
    return (
      <form>
        <h3>Shipping</h3>
        <AddressElement
          options={{
            mode: "shipping",
            defaultValues: {
              name: (
                userInfo.customers.first_name +
                " " +
                userInfo.customers.last_name
              ).trim(),
              phone: userInfo.customers.phone,
              address: {
                line1: userInfo.customers.address1,
                line2: userInfo.customers.address2,
                city: userInfo.customers.city,
                state: userInfo.customers.state,
                postal_code: userInfo.customers.zipcode,
                country: "US",
              },
            },
            fields: {
              phone: "always",
            },
            validation: {
              phone: {
                required: "always",
              },
            },
          }}
        />
      </form>
    );
  }
};

export default AddressForm;
