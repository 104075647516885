import React from "react";
import {
  // Card,
  // CardContent,
  // Container,
  Typography,
  // TableContainer,
  // Table,
  // TableHead,
  // TableRow,
  // TableCell,
  // TableBody,
  Button,
  // IconButton,
  // Input,
  Box,
  //   Divider,
} from "@mui/material";
import { useMediaQuery } from "react-responsive";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate } from "react-router-dom";
// import { removeFromCart, updateToCart } from "../actions";
// import { useDispatch } from "react-redux";
// import { useSelector } from "react-redux";
// import Cookies from "js-cookie";
// import { url } from "../url";
// import { initializeCart } from "../actions";
const CheckoutComplete = () => {
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  // const [isRemove, setRemove] = useState(false);
  // var cart = useSelector((state) => {
  //   return state.cart;
  // });
  // const [cartItems, setCartItems] = useState(cart);

  // useEffect(() => {
  //   // Create PaymentIntent as soon as the page loads
  //   // console.log("test222");
  //   fetch(url("/b/cart/"), {
  //     method: "GET",
  //     credentials: "include",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //   })
  //     .then((response) => {
  //       if (response.status === 403) {
  //         Cookies.set("islogin", "false", { path: "/" });
  //         Cookies.remove("csrftoken");
  //         navigate("/auth");
  //       }
  //       if (response.status === 404) {
  //         navigate("/main");
  //       }
  //       return response.json();
  //     })
  //     .then((res) => {
  //       const data = res.cart.map((item) => {
  //         return { product: item, quantity: item.quantity };
  //       });
  //       var action = initializeCart(data);
  //       dispatch(action);
  //       setCartItems(data);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // }, [navigate, dispatch]);

  // useEffect(() => {
  //   // console.log("test111");
  //   removeItem();
  // }, [navigate, dispatch]);

  const handleGoBack = () => {
    // removeItem();
    navigate("/main");
  };

  // const removeItem = () => {
  //   console.log("remove test!");
  //   const alluuid = [];
  //   var fetches = [];
  //   console.log(cartItems);
  //   for (const peritem of cartItems) {
  //     const updatedCartItems = cartItems.filter(
  //       (item) => item.product.uuid !== peritem.product.uuid
  //     );
  //     setCartItems(updatedCartItems);
  //     const action = removeFromCart(peritem.product.uuid);
  //     dispatch(action);
  //     fetches.push(
  //       fetch(url("/b/cart/delete/"), {
  //         method: "DELETE",
  //         credentials: "include",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //           "X-CSRFToken": Cookies.get("token"),
  //         },
  //         body: JSON.stringify({ uuid: peritem.product.uuid }),
  //       })
  //         .then((res) => {
  //           if (res.status === 403) {
  //             Cookies.set("islogin", "false", { path: "/" });
  //             Cookies.remove("csrftoken");
  //             navigate("/auth");
  //           }
  //           if (res.status === 404) {
  //             navigate("/main");
  //           }
  //         })
  //         .then((res) => {
  //           console.log("fetch test");
  //         })
  //     );
  //     Promise.all(fetches).then((res) => {
  //       setRemove(true);
  //       console.log("delete all");
  //     });
  //   }
  // };

  return (
    <Box>
      {isMobile ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "100px",
          }}
        >
          <CheckCircleIcon style={{ color: "green", fontSize: "350%" }} />
          <Typography variant="h5">Payment Successful</Typography>
          <Typography>
            Thank you for completing your secure online payment.
          </Typography>
          <Typography>Have a great day!</Typography>
          <Button
            sx={{ marginTop: "30px" }}
            onClick={() => {
              handleGoBack();
            }}
          >
            Go back
          </Button>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "100px",
          }}
        >
          <CheckCircleIcon style={{ color: "green", fontSize: "350%" }} />
          <Typography variant="h5">Payment Successful</Typography>
          <Typography>
            Thank you for completing your secure online payment.
          </Typography>
          <Typography>Have a great day!</Typography>
          <Button
            sx={{ marginTop: "30px" }}
            onClick={() => {
              handleGoBack();
            }}
          >
            Go back
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default CheckoutComplete;
