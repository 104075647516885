import React, { useState, useEffect } from "react";
import { Card, Button, Typography, TextField, Box } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
// import { useDispatch, useSelector, } from "react-redux";
import { url } from "../url";
import Cookies from "js-cookie";
import { useMediaQuery } from "react-responsive";

const ResetPassword = () => {
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const [email, setEmail] = useState("");
  const isLogin = useSelector((state) => state.islogin);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Lenwell | Reset Password";
    if (isLogin) {
      navigate("/main");
    }
  }, [isLogin, navigate]);

  const handlePasswordReset = () => {
    //use anonymous function in function component
    // if(reducer_token===''){
    if (email.trim() !== "") {
      fetch(url("/b/customer/reset/"), {
        method: "PUT",
        credentials: "include",
        headers: { "X-CSRFToken": Cookies.get("token") },
        body: JSON.stringify({ email: email }),
      })
        .then((response) => {
          if (response.status === 403) {
            Cookies.set("islogin", "false", { path: "/" });
            Cookies.remove("csrftoken");
            // const action updateLogin(false)
            navigate("/auth");
          }
          if (response.status === 404) {
            navigate("/main");
          }
          return response.json();
        })
        .then((res) => {
          // Cookies.set('token', res.token)
          alert(res.result);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      alert("Cannot be Empty");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <img
        alt="publicimage"
        style={{ width: 120, height: 40, margin: isMobile ? "20%" : "5%" }}
        className="image"
        src="https://imagelenwell.s3.us-east-2.amazonaws.com/logo/Lenwell-Digital-Logo-No-BG.png"
      />

      <Card
        sx={{
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          width: isMobile ? "80%" : "45%",
        }}
      >
        <Typography variant="h4" sx={{ marginTop: "1%" }}>
          Password Assistance
        </Typography>
        <Typography variant="h6" sx={{ marginTop: "1%" }}>
          Enter the email address associated with your Lenwell account.
        </Typography>
        <TextField
          id="reset_email"
          name="reset_name"
          label="email"
          type="email"
          required // it doesn't work if there doesn't exist a form as wrapping
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ marginTop: "10px" }}
        />
        <Button
          variant="contained"
          sx={{ marginTop: "10px" }}
          onClick={() => {
            handlePasswordReset();
          }}
        >
          Continue
        </Button>
        <Box sx={{ marginTop: "10px" }}>
          <Link to="/auth">return to login</Link>
        </Box>
      </Card>
    </Box>
  );
};
export default ResetPassword;
