import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Paper,
  Grid,
  Pagination,
} from "@mui/material";
import { useMediaQuery } from "react-responsive";
import AppbarDesktop from "../../main/appbar";
import Menu from "../../main/menu/menu";
import LeftMenu from "../left_menu/menu";
import MobileMenu from "../../main/menu/mobile_menu";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { url } from "../../url";
import { updateLogin } from "../../actions";

const DeviceStatus = () => {
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const islogin = useSelector((state) => state.islogin);
  const [tickets, setTickets] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    fetch(url(`/b/ticket/view_tickets/?page=${page}`), {
      method: "GET",
      credentials: "include",
      headers: { "X-CSRFToken": Cookies.get("token") },
    })
      .then((response) => {
        if (response.status === 403) {
          Cookies.set("islogin", "false", { path: "/" });
          Cookies.remove("csrftoken");
          const action = updateLogin(false);
          dispatch(action);
          navigate("/auth");
        }
        if (response.status === 404) {
          navigate("/main");
        }
        return response.json();
      })
      .then((data) => {
        // console.log(data.tickets);
        setTickets(data.tickets);
        setTotalPages(data.total_pages);
      })
      .catch((error) => {
        console.error("Error fetching buyback:", error);
      });
  }, [dispatch, navigate, page]);

  if (!islogin) {
    return <Navigate to="/" />;
  }

  return (
    <Box>
      <AppbarDesktop />
      {isMobile ? (
        <Box>
          <MobileMenu sx={{ flexGrow: 1 }} />
          <Link to="/account">Return to Account Menu</Link>
        </Box>
      ) : (
        <Menu />
      )}
      <Box
        sx={{
          marginLeft: !isMobile && "12%",
          marginRight: !isMobile && "10%",
          display: "flex",
          flex: 1,
          alignItems: isMobile ? "center" : "stretch",
          marginTop: isMobile ? 5 : 0,
          flexDirection: isMobile ? "column" : "row",
          paddingBottom: "2%",
        }}
      >
        {!isMobile && (
          <Paper>
            <LeftMenu />
          </Paper>
        )}
        {tickets && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginLeft: !isMobile && "50px",
              width: "90%",
            }}
          >
            {tickets.length === 0 && (
              <Typography>You don't have any existing tickets.</Typography>
            )}
            {tickets.map((ticket, index) => (
              <Card
                key={ticket.id}
                sx={{
                  minWidth: !isMobile && 450,
                  marginBottom: index < tickets.length - 1 ? "30px" : 0,
                }}
              >
                <CardHeader
                  sx={{
                    backgroundColor: "lightgray",
                    padding: "0 16px 0 16px",
                  }}
                  subheader={
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid
                        item
                        container
                        md={8}
                        sm={8}
                        xs={12}
                        spacing={3}
                        alignItems="center"
                        wrap="nowrap"
                      >
                        <Grid item my={1}>
                          <Typography
                            style={{
                              fontSize: "0.8rem",
                              color: "grey",
                            }}
                          >
                            DEVICE
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "0.8rem",
                              color: "grey",
                              textAlign: "center",
                            }}
                          >
                            # {ticket.id}
                          </Typography>
                        </Grid>
                        <Grid item my={1}>
                          <Typography
                            style={{
                              fontSize: "0.8rem",
                              color: "grey",
                            }}
                          >
                            DATE
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "0.8rem",
                              color: "grey",
                            }}
                          >
                            {ticket.created_at}
                          </Typography>
                        </Grid>
                        <Grid item my={1}>
                          <Typography
                            style={{
                              fontSize: "0.8rem",
                              color: "grey",
                            }}
                          >
                            TOTAL
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "0.8rem",
                              color: "grey",
                            }}
                          >
                            ${ticket.charge}
                          </Typography>
                        </Grid>
                        <Grid item my={1}>
                          <Typography
                            style={{
                              fontSize: "0.8rem",
                              color: "grey",
                              textAlign: "center",
                            }}
                          >
                            STATUS
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "0.8rem",
                              color: "grey",
                              textAlign: "center",
                            }}
                          >
                            {ticket.status}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  }
                />
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="body2" color="textSecondary">
                        Subject
                      </Typography>
                      <Typography variant="body1">{ticket.subject}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" color="textSecondary">
                        Task List
                      </Typography>
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {ticket.task_list.map((task, taskIndex) => (
                          <Typography
                            key={taskIndex}
                            style={{ marginRight: 8 }}
                          >
                            {task}
                          </Typography>
                        ))}
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            ))}
            <Pagination
              size="large"
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              sx={{ marginTop: "20px" }}
            ></Pagination>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DeviceStatus;
