import React, { useEffect, useState } from "react";
import {
  // Card,
  // CardContent,
  // Container,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Box,
  Divider,
} from "@mui/material";
// import DeleteIcon from "@mui/icons-material/Delete";
// import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
// import AppbarDesktop from "../main/appbar/appbarDesktop";
// import Menu from "../main/menu/menu";
// import { removeFromCart, updateToCart } from "../actions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
// import MobileMenu from "../main/menu/mobile_menu";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Cookies from "js-cookie";
import CheckoutForm from "./checkout_form";
import { url } from "../url";
import { initializeCart } from "../actions";
import AddressForm from "./address_form";

const stripePromise = loadStripe(
  "pk_test_51NSRNzJKeGaQKuTISAgVrvBRMupvhtujuvK6gWgIlUZo5iZF888m7erMuJXoldTBOz5dvGoT6UGsaZ8J6s4StwjD00YfYiIDjt"
);

const PaymentPage = () => {
  // const [shippingFee, setShippingFee] = useState(15);
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const [clientSecret, setClientSecret] = useState("");
  const [amount, setAmount] = useState(0);
  var cart = useSelector((state) => state.cart);
  const [cartItems, setCartItems] = useState(cart);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "Lenwell | Payment"; //
  }, []);

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetch(url("/b/cart/"), {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.status === 403) {
          Cookies.set("islogin", "false", { path: "/" });
          Cookies.remove("csrftoken");
          navigate("/auth");
        }
        if (response.status === 404) {
          navigate("/main");
        }
        return response.json();
      })
      .then((res) => {
        const data = res.cart.map((item) => {
          return { product: item, quantity: item.quantity };
        });
        var action = initializeCart(data);
        dispatch(action);
        setCartItems(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [navigate, dispatch]);

  // useEffect(()=>{ // this function run before setcart.then()
  //   if(getTotalPrice()<0.01){
  //     navigate("/shoppingcart")
  //   }
  // },[])

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetch(url("/b/cart/create-payment-intent/"), {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": Cookies.get("token"),
      },
      body: JSON.stringify({ cartItems: cartItems }),
    })
      .then((res) => {
        if (res.status === 403) {
          //payment error
          navigate("/shoppingcart");
        }
        return res.json();
      })
      .then((data) => {
        setClientSecret(data.clientSecret);
        setAmount(data.amount);
      });
  }, [cartItems, navigate]);

  const getTotalPrice = () => {
    return cartItems
      .reduce((total, item) => total + item.product.price * item.quantity, 0)
      .toFixed(2);
  };

  const calculateShippingCost = () => {
    const totalPrice = getTotalPrice();
    if (totalPrice >= 300) {
      return 0;
    } else {
      return 15;
    }
  };

  const shippingCost = calculateShippingCost();

  // useEffect(() => {
  //   const totalPrice = getTotalPrice();
  //   if (totalPrice >= 300) {
  //     setShippingFee(0);
  //   } else {
  //     setShippingFee(15);
  //   }
  // }, [cartItems]);

  // const appearance = {
  //   theme: "stripe",
  // };

  const options = {
    // passing the client secret obtained from the server
    clientSecret,

    layout: {
      type: "tabs",
      defaultCollapsed: false,
    },
  };
  if (clientSecret === "") {
    return <Box></Box>;
  } else if (getTotalPrice() < 0.01) {
    return (
      <Box>
        <Typography>
          Your shopping cart is empty. Please add items before proceeding to
          payment.
        </Typography>
        <Button
          variant="contained"
          onClick={() => {
            navigate("/shoppingcart");
          }}
        >
          Go Back
        </Button>
      </Box>
    );
  } else {
    return (
      <Box sx={{ marginBottom: "80px" }}>
        {isMobile ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "50px",
              marginTop: "30px",
            }}
          >
            <TableContainer sx={{ width: "360px" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Products</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* {console.log(cartItems)} */}
                  {cartItems.map((item) => (
                    <TableRow key={item.product.id}>
                      <TableCell>
                        {/* {console.log(item.product.product_name)} */}
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          {item.product.product_name}
                          <img
                            src={item.product.image_url}
                            alt={item.product.product_name}
                            style={{ width: "100px" }}
                          />
                          Amount:{item.quantity}, Total:$
                          {(item.product.price * item.quantity).toFixed(2)}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell sx={{ borderBottom: "none" }}>
                      <Box display="flex" justifyContent="space-between">
                        <Typography variant="h6">Sum Total:</Typography>
                        <Typography variant="h6">${getTotalPrice()}</Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ borderBottom: "none" }}>
                    <TableCell sx={{ borderBottom: "none" }}>
                      <Box display="flex" justifyContent="space-between">
                        <Typography variant="h6">Shipping:</Typography>
                        <Typography variant="h6">
                          ${shippingCost.toFixed(2)}
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ borderBottom: "none" }}>
                    <TableCell sx={{ borderBottom: "none" }}>
                      <Box display="flex" justifyContent="space-between">
                        <Typography style={{ color: "red" }}>
                          free shipping for orders over $300
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ borderBottom: "none" }}>
                    <TableCell sx={{ borderBottom: "none" }}>
                      <Box display="flex" justifyContent="space-between">
                        <Typography variant="h6">Total Price:</Typography>
                        <Typography variant="h6">
                          $
                          {(parseFloat(getTotalPrice()) + shippingCost).toFixed(
                            2
                          )}
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "360px",
                marginTop: "30px",
              }}
            >
              {/* {console.log(options)} */}
              <Elements stripe={stripePromise} options={options}>
                <AddressForm />
                <Divider sx={{ margin: "10px" }} />
                <CheckoutForm amount={amount} />
              </Elements>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{ display: "flex", flexDirection: "row", marginTop: "60px" }}
            >
              <Box sx={{ marginTop: "50px", marginRight: "60px" }}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Products</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {cartItems.map((item) => (
                        <TableRow key={item.product.id}>
                          <TableCell>
                            <Box
                              sx={{ display: "flex", flexDirection: "column" }}
                            >
                              {item.product.product_name}
                              <img
                                src={item.product.image_url}
                                alt={item.product.product_name}
                                style={{ width: "100px" }}
                              />
                              Amount: {item.quantity}, Total: $
                              {(item.product.price * item.quantity).toFixed(2)}
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}

                      <TableRow>
                        <TableCell sx={{ borderBottom: "none" }}>
                          <Box display="flex" justifyContent="space-between">
                            <Typography variant="h6">Sum Total:</Typography>
                            <Typography variant="h6">
                              ${getTotalPrice()}
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ borderBottom: "none" }}>
                        <TableCell sx={{ borderBottom: "none" }}>
                          <Box display="flex" justifyContent="space-between">
                            <Typography variant="h6">Shipping:</Typography>
                            <Typography variant="h6">
                              ${shippingCost.toFixed(2)}
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ borderBottom: "none" }}>
                        <TableCell sx={{ borderBottom: "none" }}>
                          <Box display="flex" justifyContent="space-between">
                            <Typography style={{ color: "red" }}>
                              free shipping for orders over $300
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ borderBottom: "none" }}>
                        <TableCell sx={{ borderBottom: "none" }}>
                          <Box display="flex" justifyContent="space-between">
                            <Typography variant="h6">Total Price:</Typography>
                            <Typography variant="h6">
                              $
                              {(
                                parseFloat(getTotalPrice()) + shippingCost
                              ).toFixed(2)}
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "460px",
                  marginTop: "50px",
                }}
              >
                <Elements stripe={stripePromise} options={options}>
                  <AddressForm />
                  <Divider sx={{ margin: "10px" }} />
                  <CheckoutForm amount={amount} />
                </Elements>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    );
  }
};

export default PaymentPage;
