import React from "react";
import {
  Box,
  MenuList,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Typography,
  Divider,
} from "@mui/material";
// import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { Link, useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AssignmentIcon from "@mui/icons-material/Assignment";
import SavingsIcon from "@mui/icons-material/Savings";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { updateLogin } from "../../actions";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { url } from "../../url";
import { useMediaQuery } from "react-responsive";

function LeftMenu(props) {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 600 });

  const handleLogOut = () => {
    fetch(url("/b/customer/logout/"), {
      method: "PUT",
      credentials: "include",
      headers: { "X-CSRFToken": Cookies.get("token") },
    })
      .then((res) => res.json())
      .then((res) => {
        Cookies.set("islogin", "false", { path: "/" });
        Cookies.remove("csrftoken");
        const action = updateLogin(false);
        dispatch(action);
        navigate("/auth");
      });
  };

  // const handleBalanceClick = () => {
  //   alert("It will be available soon!");
  // };

  const handleStoreClick = () => {
    alert("It will be available soon!");
  };

  // const handleSupportClick = () => {
  //   alert("it will be available soon!");
  // };

  return (
    <Box>
      {isMobile ? (
        <Box>
          <MenuList>
            <Divider />
            <Typography variant="h6" sx={{ padding: "10px 15px" }}>
              My Account
            </Typography>

            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <MenuItem component={Link} to={"/main"}>
                <ListItemIcon>
                  <KeyboardReturnIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText style={{ textDecoration: "none" }}>
                  {" "}
                  <Typography variant="h9">Return Main</Typography>{" "}
                </ListItemText>
              </MenuItem>
              <MenuItem onClick={handleLogOut}>
                <ListItemIcon>
                  <LogoutIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText style={{ textDecoration: "none" }}>
                  Logout
                </ListItemText>
              </MenuItem>
            </Box>
            <MenuItem component={Link} to={"/account/dashboard"}>
              <ListItemIcon>
                <AccountCircleIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Dashboard</ListItemText>
            </MenuItem>
            <Divider />
            <Typography variant="h6" sx={{ padding: "10px 15px" }}>
              Order Information
            </Typography>
            <MenuItem component={Link} to={"/account/orders"}>
              <ListItemIcon>
                <ShoppingCartIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Existing Orders</ListItemText>
            </MenuItem>
            <Divider />
            <Typography variant="h6" sx={{ padding: "10px 15px" }}>
              Status
            </Typography>
            <MenuItem component={Link} to={"/account/invoices"}>
              <ListItemIcon>
                <ReceiptIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Invoices</ListItemText>
            </MenuItem>
            <MenuItem component={Link} to={"/account/devices"}>
              <ListItemIcon>
                <ConfirmationNumberIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Devices</ListItemText>
            </MenuItem>
            <Divider />
            <Typography variant="h6" sx={{ padding: "10px 15px" }}>
              Buyback
            </Typography>
            <MenuItem component={Link} to={"/account/buyback"}>
              <ListItemIcon>
                <AssignmentIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Applications</ListItemText>
            </MenuItem>
            <MenuItem component={Link} to={"/account/buyback-report"}>
              <ListItemIcon>
                <SummarizeIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Report</ListItemText>
            </MenuItem>
            <Divider />
            <Typography variant="h6" sx={{ padding: "10px 15px" }}>
              Gift Card
            </Typography>
            {/* <MenuItem
              onClick={() => {
                handleBalanceClick();
              }}
            >
              <ListItemIcon>
                <AccountBalanceIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>BALANCE SHEET</ListItemText>
            </MenuItem> */}
            <MenuItem
              onClick={() => {
                handleStoreClick();
              }}
            >
              <ListItemIcon>
                <SavingsIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>STORE CREDIT</ListItemText>
            </MenuItem>
            {/* <Divider />
            <Typography variant="h6" sx={{ padding: "10px 15px" }}>
              SERVICES
            </Typography>
            <MenuItem
              onClick={() => {
                handleSupportClick();
              }}
            >
              <ListItemIcon>
                <Cloud fontSize="small" />
              </ListItemIcon>
              <ListItemText>SUPPORT TICKET</ListItemText>
            </MenuItem> */}
            <Divider />
          </MenuList>
        </Box>
      ) : (
        <Box sx={{}}>
          <MenuList>
            <Divider />
            <Typography variant="h6" sx={{ padding: "10px 15px" }}>
              My Account
            </Typography>

            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <MenuItem component={Link} to={"/main"}>
                <ListItemIcon>
                  <KeyboardReturnIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText style={{ textDecoration: "none" }}>
                  Return Main
                </ListItemText>
              </MenuItem>
              <MenuItem onClick={handleLogOut}>
                <ListItemIcon>
                  <LogoutIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText style={{ textDecoration: "none" }}>
                  Logout
                </ListItemText>
              </MenuItem>
            </Box>
            <MenuItem component={Link} to={"/account/dashboard"}>
              <ListItemIcon>
                <AccountCircleIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Dashboard</ListItemText>
            </MenuItem>
            <Divider />
            <Typography variant="h6" sx={{ padding: "10px 15px" }}>
              Order Information
            </Typography>
            <MenuItem component={Link} to={"/account/orders"}>
              <ListItemIcon>
                <ShoppingCartIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Existing Orders</ListItemText>
            </MenuItem>
            <Divider />
            <Typography variant="h6" sx={{ padding: "10px 15px" }}>
              Status
            </Typography>
            <MenuItem component={Link} to={"/account/invoices"}>
              <ListItemIcon>
                <ReceiptIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Invoices</ListItemText>
            </MenuItem>
            <MenuItem component={Link} to={"/account/devices"}>
              <ListItemIcon>
                <ConfirmationNumberIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Devices</ListItemText>
            </MenuItem>
            <Divider />
            <Typography variant="h6" sx={{ padding: "10px 15px" }}>
              Buyback
            </Typography>
            <MenuItem component={Link} to={"/account/buyback"}>
              <ListItemIcon>
                <AssignmentIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Applications</ListItemText>
            </MenuItem>
            <MenuItem component={Link} to={"/account/buyback-report"}>
              <ListItemIcon>
                <SummarizeIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Report</ListItemText>
            </MenuItem>
            <Divider />
            <Typography variant="h6" sx={{ padding: "10px 15px" }}>
              Gift Card
            </Typography>
            {/* <MenuItem
              onClick={() => {
                handleBalanceClick();
              }}
            >
              <ListItemIcon>
                <AccountBalanceIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>BALANCE SHEET</ListItemText>
              <Typography variant="body2" color="text.secondary">
                $0.00
              </Typography>
            </MenuItem> */}
            <MenuItem
              onClick={() => {
                handleStoreClick();
              }}
            >
              <ListItemIcon>
                <SavingsIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>STORE CREDIT</ListItemText>
              <Typography variant="body2" color="text.secondary">
                $0.00
              </Typography>
            </MenuItem>
            <Divider />
            {/* <Typography variant="h6" sx={{ padding: "10px 15px" }}>
              SERVICES
            </Typography>
            <MenuItem
              onClick={() => {
                handleSupportClick();
              }}
            >
              <ListItemIcon>
                <Cloud fontSize="small" />
              </ListItemIcon>
              <ListItemText>SUPPORT TICKET</ListItemText>
            </MenuItem>
            <Divider /> */}
          </MenuList>
        </Box>
      )}
    </Box>
  );
}

export default LeftMenu;
