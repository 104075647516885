// import React, { Component } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
// import { shadows } from "@mui/system";

//menu contianer
export const MenuContianer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  marginTop: 5,
  marginLeft: 0,
  justifyContent: "center",
  alignItems: "center",
  padding: "2px, 8px",
  borderTop: "1px solid", // border set format
  // borderTop:3,
  // border:2,
  // boxShadow: 3,
}));
