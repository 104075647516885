import React from "react";
import { Typography, Box } from "@mui/material";
// import { Container, Grid, Typography, Box, Button } from "@mui/material";
// import { makeStyles } from "@mui/styles";
// import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

// const useStyles = makeStyles((theme) => ({
//   footer: {
//     backgroundColor: "grey",
//     color: "#ffffff",
//     padding: 2,
//   },
//   footerText: {
//     textAlign: "center",
//   },
// }));

const Footer = () => {
  // const classes = useStyles();
  // const date = new Date();
  // const year = date.getFullYear();
  const isMobile = useMediaQuery({ maxWidth: 600 });
  let navigate = useNavigate();

  return (
    // <footer>
    //   <Container sx={{width:"100%" }}>
    //     <Typography variant="body2" color="textSecondary" align="center">
    //       {'Made with love by '}
    //       <Link color="inherit" href="https://www.example.com/">
    //         Your Name
    //       </Link>
    //       {' © '}
    //       {new Date().getFullYear()}
    //     </Typography>
    //   </Container>
    // </footer>
    <Box sx={{marginTop:"350px"}}>
      {isMobile ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          backgroundColor="black"
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography color={"white"}>
              © 2023, LenwellDigital.com Powered by LenwellDigital
            </Typography>
            {/* <Box width={5}></Box> */}
            <Typography
              color={"white"}
              onClick={() => {
                // console.log("typo test");
                navigate("/privacy");
              }}
            >
              Privacy Policy
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          backgroundColor="black"
        >
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Typography color={"white"}>
              © 2023, LenwellDigital.com Powered by LenwellDigital
            </Typography>
            <Box width={300}></Box>
            <Typography
              color={"white"}
              sx={{ cursor: "pointer" }}
              onClick={() => {
                // console.log("typo test");
                navigate("/privacy");
              }}
            >
              Privacy Policy
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Footer;
