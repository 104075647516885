import React from 'react';
import './chat-box.css';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Box } from '@mui/material';


const ChatBox = () =>{
    const handleClick =()=>{
        window.open("https://api.whatsapp.com/send?phone=18322755999", "_blank");
        // console.log("click button!");
    }

    return (
        <Box > <WhatsAppIcon className='chat-box' onClick={handleClick}/> </Box>
    )
}

export default ChatBox;

