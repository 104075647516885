import React, { useState, useEffect } from "react";
// import {
//   Button,
//   Divider,
//   Input,
//   ListItemButton,
//   ListItemIcon,
//   ListItemText,
//   TextField,
//   AppBar,
//   Toolbar,
//   IconButton,
// } from "@mui/material";
// import MenuIcon from "@mui/icons-material/Menu";
// import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import AppbarDesktop from "./appbar";
import Menu from "./menu/menu";
import Imagecarousel from "./carousel/image_carousel";
import ProductList from "./products/product_list";
import { Typography, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateLogin } from "../actions";
import Cookies from "js-cookie";
import { url } from "../url";
import { useMediaQuery } from "react-responsive";
// import DrawerMenu from "./menu/drawerMenu";
import MobileMenu from "./menu/mobile_menu";
import Footer from "../publicity/footer";
// import {ChatBox} from "./chat/chatBox";
import ChatBox from "./chat/chatBox";

const Main = () => {
  const [Data, setData] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLogin = useSelector((state) => state.islogin);
  const isMobile = useMediaQuery({ maxWidth: 600 });
  // const [isMenuOpen, setIsMenuOpen] = useState(false);
  // console.log("main is login:"+isLogin)

  // const handleMenuClick = () => {
  //   // 处理菜单按钮点击逻辑
  //   handleMenuToggle();
  //   // console.log('Menu button clicked');
  // };
  useEffect(() => {
    document.title = "Lenwell | Main"; //
  }, []);

  // const handleMenuToggle = () => {
  //   setIsMenuOpen(false);
  // };

  // const handleOutsideClick = () => {
  //   console.log("outside click");

  //   if (isMenuOpen) {
  //     setIsMenuOpen(false);
  //   }
  // };

  useEffect(() => {
    //fix navigate before first render by putting navigate function into useEffect.
    if (!isLogin) {
      // console.log("main navigate to /")
      navigate("/");
    } else {
      fetch(url("/b/inventory/search/?keyword=iPhone 14"), {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 403) {
            Cookies.set("islogin", "false", { path: "/" });
            Cookies.remove("csrftoken");
            const action = updateLogin(false);
            dispatch(action);
            navigate("/auth");
          }
          if (response.status === 404) {
            navigate("/");
          }
          return response.json();
        })
        .then((data) => {
          setData(data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [isLogin, dispatch, navigate]);

  if (Data !== null) {
    return (
      <div>
        {isMobile ? (
          <Box
          // onClick={() => {
          //   handleOutsideClick();
          // }}
          >
            <AppbarDesktop />
            <MobileMenu sx={{ flexGrow: 1 }} />
            <Imagecarousel />
            {/* <DrawerMenu isOpen={isMenuOpen} onClose={handleMenuToggle} /> */}
            <ProductList products={Data.products} />
          </Box>
        ) : (
          <Box>
            <AppbarDesktop />
            <Menu />
            <Imagecarousel />
            <ProductList products={Data.products} />
            <Footer />
            <ChatBox />
          </Box>
        )}
      </div>
    );
  } else {
    return (
      <div>
        <AppbarDesktop />
        <Menu />
        <Imagecarousel />
        <Typography variant="h3" style={{ marginLeft: "160px" }}></Typography>
      </div>
    );
  }
};

export default Main;
