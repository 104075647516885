import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  Button,
  Divider,
  Snackbar,
  SnackbarContent,
  Alert,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addToCart, updateCurretnProduct } from "../../actions";
import { useMediaQuery } from "react-responsive";
import { url } from "../../url";
import Cookies from "js-cookie";

const ProductList = ({ products }) => {
  var cur_page_product_count = {};
  const [current_page_products, set_Current_page_products] = useState(
    cur_page_product_count
  ); //create a state data in this page
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const [notification, setNotification] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const update_count = () => {
    cur_page_product_count = {};
    // console.log(products);
    if (products !== undefined) {
      // it could be forbidden.
      products.forEach((element) => {
        cur_page_product_count[element.uuid] = 1;
        // console.log(element.id)
      });
    }
    set_Current_page_products(cur_page_product_count);
  };

  useEffect(() => {
    //detect data, rerender when data is changed.
    update_count();
  }, [products]);

  const handleIncrement = (product) => {
    var uuid = product.uuid;

    const updataDictionary = {
      ...current_page_products,
      [uuid]: current_page_products[product.uuid] + 1,
    }; //update key using this method.

    set_Current_page_products(updataDictionary);
  };

  const handleDecrement = (product) => {
    var uuid = product.uuid;
    if (current_page_products[product.uuid] > 1) {
      const updataDictionary = {
        ...current_page_products,
        [uuid]: current_page_products[product.uuid] - 1,
      };
      set_Current_page_products(updataDictionary);
    }
  };

  const handleClick = (product) => {
    var amount = current_page_products[product.uuid];
    const updataDictionary = { ...current_page_products, [product.uuid]: 1 };
    set_Current_page_products(updataDictionary);
    var formData = {
      uuid: product.uuid,
      quantity: amount,
    };

    fetch(url("/b/cart/add/"), {
      method: "POST",
      credentials: "include",
      headers: { "X-CSRFToken": Cookies.get("token") },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.status === 403) {
          Cookies.set("islogin", "false", { path: "/" });
          Cookies.remove("token");
          navigate("/auth");
        }
        if (response.status === 404) {
          navigate("/main");
        }
        return response.json();
      })
      .then((res) => {
        set_Current_page_products(updataDictionary);
        const action = addToCart(product, amount);
        dispatch(action);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleAddToCart = (productId) => {
    setNotification("Item added to cart");
    handleClick(productId);
  };

  const handleCloseNotification = () => {
    setNotification("");
  };

  //update product single page
  const handLinkleClick = (item) => {
    const action = updateCurretnProduct(item);
    dispatch(action);
  };

  if (Object.keys(current_page_products).length !== 0) {
    return (
      <Box>
        {isMobile ? (
          <Box sx={{ marginBottom: "60px" }}>
            <Grid container spacing={1}>
              {products.map((product) => (
                <Grid key={product.id} item xs={6}>
                  <Card>
                    <Link to={{ pathname: `/products/${product.uuid}` }}>
                      <CardMedia
                        component="img"
                        height="200"
                        image={product.product_url_list[0]}
                        alt={product.product_name}
                        onClick={() => {
                          handLinkleClick({ product, quantity: 1 });
                        }}
                        sx={{
                          height: "100%",
                          display: "block",
                          overflow: "hidden",
                          width: "100%",
                        }}
                      />
                    </Link>
                    <CardContent>
                      <Box sx={{ height: "60px" }}>
                        <Typography
                          variant="h9"
                          style={{ fontWeight: "700", fontSize: "12px" }}
                        >
                          {product.product_name}
                        </Typography>
                      </Box>

                      <Typography variant="subtitle1" color="textPrimary">
                        Price: ${product.price_b}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleDecrement(product)}
                        >
                          -
                        </Button>
                        <Typography
                          id={product.id}
                          sx={{
                            paddingLeft: "20px",
                            paddingRight: "20px",
                            paddingTop: "6px",
                          }}
                        >
                          {current_page_products[product.uuid]}
                        </Typography>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleIncrement(product)}
                        >
                          +
                        </Button>
                      </Box>

                      <Divider sx={{ paddingTop: "5px" }} />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleAddToCart(product)}
                          sx={{ marginTop: "5px" }}
                        >
                          Add to Cart
                        </Button>
                        <Snackbar
                          open={Boolean(notification)}
                          autoHideDuration={3000}
                          onClose={handleCloseNotification}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          style={{ marginTop: "40px" }} // Adjust the marginTop value to move the Snackbar higher
                        >
                          <SnackbarContent
                            sx={{
                              backgroundColor: "#ffcc00", // Customize the background color here
                            }}
                            message={
                              <Alert
                                onClose={handleCloseNotification}
                                severity="success"
                              >
                                {notification}
                              </Alert>
                            }
                          />
                        </Snackbar>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        ) : (
          <Box sx={{ marginLeft: 20, marginRight: 20, marginBottom: 20 }}>
            <Grid container spacing={2}>
              {products.map((product) => (
                <Grid item key={product.id} xs={12} sm={6} md={4} lg={3}>
                  <Card>
                    <Link to={{ pathname: `/products/${product.uuid}` }}>
                      <CardMedia
                        component="img"
                        height="200"
                        image={product.product_url_list[0]}
                        alt={product.product_name}
                        onClick={() => {
                          handLinkleClick({ product, quantity: 0 });
                        }}
                        sx={{
                          height: "100%",
                          display: "block",
                          overflow: "hidden",
                          width: "100%",
                        }}
                      />
                    </Link>
                    <CardContent>
                      <Box sx={{ height: "60px" }}>
                        <Typography
                          variant="h9"
                          style={{ fontWeight: "700", fontSize: "12px" }}
                        >
                          {product.product_name}
                        </Typography>
                      </Box>

                      <Typography variant="subtitle1" color="textPrimary">
                        Price: ${product.price_b}
                      </Typography>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleDecrement(product)}
                        >
                          -
                        </Button>
                        <Typography
                          id={product.id}
                          sx={{
                            paddingLeft: "20px",
                            paddingRight: "20px",
                            paddingTop: "6px",
                          }}
                        >
                          {current_page_products[product.uuid]}
                        </Typography>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleIncrement(product)}
                        >
                          +
                        </Button>
                      </Box>

                      <Divider sx={{ paddingTop: "5px" }} />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleAddToCart(product)}
                          sx={{ marginTop: "5px" }}
                        >
                          Add to Cart
                        </Button>
                        <Snackbar
                          open={Boolean(notification)}
                          autoHideDuration={3000}
                          onClose={handleCloseNotification}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          style={{ marginTop: "40px" }} // Adjust the marginTop value to move the Snackbar higher
                        >
                          <SnackbarContent
                            sx={{
                              backgroundColor: "#ffcc00", // Customize the background color here
                            }}
                            message={
                              <Alert
                                onClose={handleCloseNotification}
                                severity="success"
                              >
                                {notification}
                              </Alert>
                            }
                          />
                        </Snackbar>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </Box>
    );
  } else {
    return (
      // <h1 >Loading</h1>
      <Box>
        {isMobile ? (
          <Typography variant="h6" style={{ marginLeft: "16px" }}>
            Coming soon ...
          </Typography>
        ) : (
          <Typography variant="h6" style={{ marginLeft: "160px" }}>
            Coming soon ...
          </Typography>
        )}
      </Box>
    );
  }
};

export default ProductList;
