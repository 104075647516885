import React, { useState, useEffect } from "react";
import {
  Input,
  Table,
  TableBody,
  Typography,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Box,
  Button,
} from "@mui/material";
import AppbarDesktop from "../main/appbar/appbarDesktop";
import Menu from "../main/menu/menu";
import { useMediaQuery } from "react-responsive";
import MobileMenu from "../main/menu/mobile_menu";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { url } from "../url";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const labelTableCellStyle = {
  backgroundColor: "#003366",
  color: "#FFFFFF",
};

const BuybackLabel = () => {
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [quantities, setQuantities] = useState({});
  const [brands, setBrands] = useState([]);

  const [comment, setComment] = useState("");
  const [paymentMethod, setPaymentMethod] = useState(null);

  useEffect(() => {
    document.title = "Lenwell | Buyback Label";
    fetch(url(`/b/buyback/pricing-table/`), {
      method: "GET",
      credentials: "include",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        const newData = res.data.map((item) => ({
          id: item.id,
          brand: item.brand,
          model: item.model,
          price: Math.max(item.grade_a, item.grade_ab, item.grade_abc),
        }));
        setData(newData);
        setQuantities(
          newData.reduce((acc, item) => {
            const { id, model, price } = item;
            acc[id] = { model, quantity: 0, price };
            return acc;
          }, {})
        );
        setBrands(res.brands);
      })
      .catch((error) => console.error(error));
  }, []);

  const handleQuantityChange = (productId, quantity) => {
    if (isNaN(quantity) || quantity < 1 || quantity > 500) {
      quantity = 0;
    }
    const newQuantities = {
      ...quantities,
      [productId]: {
        ...quantities[productId],
        quantity: quantity,
      },
    };
    setQuantities(newQuantities);
    const newTotalPrice = Object.values(newQuantities).reduce(
      (total, details) => {
        const { quantity, price } = details || { quantity: 0, price: 0 };
        return total + quantity * price;
      },
      0
    );
    setTotalPrice(newTotalPrice);
  };

  const handleBlur = (productId) => {
    if (quantities[productId].quantity === "") {
      setQuantities({
        ...quantities,
        [productId]: {
          ...quantities[productId],
          quantity: 0,
        },
      });
    }
  };

  const handleApplicationSubmit = () => {
    const itemsToSend = Object.values(quantities)
      .map((details) => {
        const { model, quantity, price } = details || {
          model: "",
          quantity: 0,
          price: 0,
        };
        return { product: model, quantity, price };
      })
      .filter((item) => item.quantity > 0);
    const dataToSend = {
      comment_list: comment,
      payment_method: paymentMethod,
      charge: totalPrice,
      item_list: itemsToSend,
    };
    fetch(url("/b/buyback/send-buyback-application/"), {
      method: "POST",
      credentials: "include",
      headers: { "X-CSRFToken": Cookies.get("token") },
      body: JSON.stringify(dataToSend),
    })
      .then((response) => {
        if (response.status === 403) {
          Cookies.set("islogin", "false", { path: "/" });
          Cookies.remove("token");
          navigate("/auth");
        }
        if (response.status === 404) {
          navigate("/main");
        }
        return response.json();
      })
      .then(() => {
        navigate("../account/buyback");
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  };

  return (
    <Box>
      <AppbarDesktop />
      {isMobile ? <MobileMenu /> : <Menu />}
      {data && (
        <Box
          sx={{
            marginLeft: isMobile ? "1%" : "12%",
            marginRight: isMobile ? "1%" : "10%",
            display: "flex",
            justifyContent: "center",
            flex: 1,
            alignItems: isMobile ? "center" : "stretch",
            // marginTop: isMobile ? 5 : 0,
            paddingBottom: "2%",
            overflowX: isMobile ? "auto" : "hidden",
          }}
        >
          <Box>
            <Box fontSize="1.5em" fontWeight="bold" mb={2}>
              FORM TO REQUEST LCD BUYBACK LABEL
            </Box>
            {brands.map((category) => (
              <Box key={category} mb={3}>
                <TableContainer
                  component={Paper}
                  sx={{
                    borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
                    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  }}
                >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{ ...labelTableCellStyle, width: "60%" }}
                        >
                          {category}
                        </TableCell>
                        <TableCell
                          style={{ ...labelTableCellStyle, width: "20%" }}
                        >
                          Price
                        </TableCell>
                        <TableCell
                          style={{ ...labelTableCellStyle, width: "20%" }}
                        >
                          Quantity
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data
                        .filter((row) => row["brand"] === category)
                        .map((row, index) => (
                          <TableRow
                            key={row.id}
                            style={{
                              backgroundColor:
                                index % 2 === 0 ? "white" : "#f5f5f5",
                            }}
                          >
                            <TableCell
                              style={{
                                borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                              }}
                            >
                              {row.model}
                            </TableCell>
                            <TableCell
                              style={{
                                borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                              }}
                            >
                              ${row.price}
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2">
                                <Input
                                  as="span"
                                  value={quantities[row.id].quantity}
                                  // type="number" // remove it to stop negative
                                  onBlur={() => handleBlur(row.id)}
                                  onChange={(e) =>
                                    handleQuantityChange(
                                      row.id,
                                      parseInt(e.target.value) || e.target.value
                                    )
                                  }
                                />{" "}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            ))}
            <Box>
              <Paper style={labelTableCellStyle}>
                <Typography
                  component="div"
                  sx={{
                    width: "100%",
                    color: "white",
                    textAlign: "center",
                    fontWeight: "bold",
                    padding: "10px",
                  }}
                >
                  <span>Estimate PayOut: </span>
                  <span style={{ color: "red" }}>
                    $0 to ${totalPrice.toFixed(2)}
                  </span>
                </Typography>
              </Paper>
            </Box>
            <Box mt={3}>
              <TextField
                fullWidth
                label="Comment"
                variant="outlined"
                multiline
                rows={4}
                margin="normal"
                value={comment}
                placeholder="If the shipping address is not the default address or the payment method is not among the options below, please make a note. Our staff will get in touch with you to discuss and negotiate the price. Thank you."
                onChange={(e) => setComment(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <InputLabel id="demo-simple-select-label">
                Payment Method
              </InputLabel>
              <Select
                labelId="payment-method"
                id="payment_method"
                value={paymentMethod ? paymentMethod : ""}
                // label="payment_method"
                onChange={(e) => setPaymentMethod(e.target.value)}
                // defaultValue=""
                style={{ width: "100%" }}
              >
                <MenuItem value="Store Credit">Store Credit</MenuItem>
                <MenuItem value="Wire Transfer">Wire Transfer</MenuItem>
                <MenuItem value="Zelle">Zelle</MenuItem>
                <MenuItem value="Cash App">Cash App</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
            </Box>
            <Button
              variant="contained"
              style={{
                marginTop: "2%",
                marginBottom: "2%",
              }}
              disabled={!paymentMethod || totalPrice === 0}
              onClick={handleApplicationSubmit}
            >
              Submit
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default BuybackLabel;
