import React, { useState } from "react";
// import { connect } from "react-redux";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";

import { Box } from "@mui/material";
import { useMediaQuery } from "react-responsive";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  // Images data...
  {
    label: "Image1",
    imgPath:
      "https://imagelenwell.s3.us-east-2.amazonaws.com/logo/joel-rohland-MCL2xxjZJvs-unsplash-scaled.jpg",
  },
  {
    label: "Image2",
    imgPath:
      "https://imagelenwell.s3.us-east-2.amazonaws.com/logo/insung-yoon-dRUqU4RpeOE-unsplash-scaled.jpg",
  },
  // {
  //     label: 'Image3',
  //     imgPath:
  //         'https://i.imgur.com/vfiObc6.jpg',
  // },
  // {
  //     label: 'Image4',
  //     imgPath:
  //         'https://i.imgur.com/pbMMxlv.jpg',
  // },
];

const ImageCarousel = ({ islogin }) => {
  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();
  const maxSteps = images.length;
  const isMobile = useMediaQuery({ maxWidth: 600 });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box>
      {isMobile ? (
        <Box>
          <Box sx={{ marginLeft: 2, marginRight: 2, flexGrow: 1 }}>
            <Paper
              square
              elevation={0}
              sx={{
                display: "flex",
                alignItems: "center",
                height: 50,
                pl: 2,
                bgcolor: "background.default",
              }}
            ></Paper>
            <AutoPlaySwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
            >
              {images.map((step, index) => (
                <div key={step.label}>
                  {Math.abs(activeStep - index) <= 2 ? (
                    <Link to={{ pathname: "/main" }}>
                      <Box
                        component="img"
                        sx={{
                          height: 200,
                          display: "block",
                          overflow: "hidden",
                          width: "100%",
                        }}
                        src={step.imgPath}
                        alt={step.label}
                      />
                    </Link>
                  ) : null}
                </div>
              ))}
            </AutoPlaySwipeableViews>
            <MobileStepper
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1}
                >
                  Next
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                  Back
                </Button>
              }
            />
          </Box>
        </Box>
      ) : (
        <Box sx={{ marginLeft: 20, marginRight: 20, flexGrow: 1 }}>
          <Paper
            square
            elevation={0}
            sx={{
              display: "flex",
              alignItems: "center",
              height: 50,
              pl: 2,
              bgcolor: "background.default",
            }}
          ></Paper>
          <AutoPlaySwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
            {images.map((step, index) => (
              <div key={step.label}>
                {Math.abs(activeStep - index) <= 2 ? (
                  <Link to={{ pathname: "/main" }}>
                    <Box
                      component="img"
                      sx={{
                        height: 400,
                        display: "block",
                        overflow: "hidden",
                        width: "100%",
                      }}
                      src={step.imgPath}
                      alt={step.label}
                    />
                  </Link>
                ) : null}
              </div>
            ))}
          </AutoPlaySwipeableViews>
          <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1}
              >
                Next
                {theme.direction === "rtl" ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                {theme.direction === "rtl" ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
                Back
              </Button>
            }
          />
        </Box>
      )}
    </Box>
  );
};

export default ImageCarousel;
