import { Box, TextField, Toolbar, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
// import { useMediaQuery } from "react-responsive";
import DrawerMenu from "./drawerMenu";

const MobileMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [time, setTime] = useState();
  const navigate = useNavigate();
  const buttonRef = useRef(null);

  const handleSearchKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch(event);
    }
  };

  const handleSearchButtonClick = (event) => {
    handleSearch(event);
  };
  const handleSearch = (event) => {
    const searchQuery = event.target.value;
    if (searchQuery !== "") {
      navigate("/productlist/" + searchQuery);
    }
  };

  const handleMenuClick = () => {
    handleMenuToggle();
    // console.log(isMenuOpen)
  };
  const handleMenuToggle = () => {
    setTime(Date.now());
    // console.log(time)

    setIsMenuOpen(true);
    // this.forceUpdate();
  };

  // const handleOutsideClick = () => {
  //   // console.log("outside click")

  //   if (isMenuOpen) {
  //     setIsMenuOpen(false);
  //   }
  // };

  return (
    <Box
      sx={{ flexGrow: 1 }}
      onClick={(e) => {
        // handleOutsideClick();
        // console.log("box:")
        // console.log(isMenuOpen);
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="menu"
          onClick={handleMenuClick}
          ref={(ref) => (buttonRef.current = ref)}
        >
          <MenuIcon />
        </IconButton>
        <TextField
          disabled
          // placeholder="Search"
          variant="outlined"
          size="small"
          sx={{ flexGrow: 1, ml: 1 }}
          onKeyPress={handleSearchKeyPress}
          placeholder="Currently Unavailable"
        />
        <IconButton
          color="inherit"
          aria-label="search"
          onClick={handleSearchButtonClick}
        >
          <SearchIcon />
        </IconButton>
        {/* {console.log("<>test:"+isMenuOpen)} */}
        <DrawerMenu
          isOpen={isMenuOpen}
          onClose={handleMenuToggle}
          time={time}
        />
      </Toolbar>
    </Box>
  );
};
export default MobileMenu;
