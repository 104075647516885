import React, { useEffect, useState } from "react";
import { Box, Typography, Paper, Card, CardContent } from "@mui/material";
import { Link, useNavigate, Navigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import AppbarDesktop from "../../main/appbar";
import Menu from "../../main/menu/menu";
import LeftMenu from "../left_menu/menu";
import MobileMenu from "../../main/menu/mobile_menu";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { url } from "../../url";
import { updateLogin } from "../../actions";

const BuybackApplication = () => {
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const islogin = useSelector((state) => state.islogin);
  const [buyback, setBuyback] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    fetch(url("/b/buyback/"), {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 403) {
          Cookies.set("islogin", "false", { path: "/" });
          Cookies.remove("csrftoken");
          const action = updateLogin(false);
          dispatch(action);
          navigate("/auth");
        }
        if (response.status === 404) {
          navigate("/main");
        }
        return response.json();
      })
      .then((res) => {
        // console.log(data);
        setBuyback(res.data);
      })
      .catch((error) => {
        console.error("Error fetching buyback:", error);
      });
  }, [dispatch, navigate]);

  if (!islogin) {
    return <Navigate to="/" />;
  }

  return (
    <Box>
      <AppbarDesktop />
      {isMobile ? (
        <Box>
          <MobileMenu sx={{ flexGrow: 1 }} />
          <Link to="/account">Return to Account Menu</Link>
        </Box>
      ) : (
        <Menu />
      )}
      <Box
        sx={{
          marginLeft: !isMobile && "12%",
          marginRight: !isMobile && "10%",
          display: "flex",
          flex: 1,
          alignItems: isMobile ? "center" : "stretch",
          marginTop: isMobile ? 5 : 0,
          flexDirection: isMobile ? "column" : "row",
          paddingBottom: "2%",
        }}
      >
        {!isMobile && (
          <Paper>
            <LeftMenu />
          </Paper>
        )}
        {buyback && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginLeft: !isMobile && "50px",
              width: "90%",
            }}
          >
            {buyback.length === 0 && (
              <Typography>
                You don't have any existing LCD buyback applications.
              </Typography>
            )}

            {buyback.map((buyback) => (
              <Card key={buyback.id} sx={{ mb: 2 }}>
                <CardContent>
                  <Typography variant="h6" component="div">
                    {"# " + buyback.id} - {buyback.company || "Status"} -{" "}
                    {buyback.status}
                  </Typography>
                  <Typography variant="body2">
                    Phone: {buyback.phone}
                  </Typography>
                  <Typography variant="body2">
                    Email: {buyback.email}
                  </Typography>
                  {/* <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    Address: {buyback.customer_address}
                  </Typography> */}
                  <br />
                  {buyback.item_list.map((item, index) => (
                    <Typography key={index} variant="body2">
                      {/* {item.product}: ${item.price.toFixed(2)} x {item.quantity} */}
                      {item.product}: ${Number(item.price).toFixed(2)} x{" "}
                      {item.quantity}
                    </Typography>
                  ))}
                  <br />
                  <Typography variant="body2">
                    {buyback.status === "Pending"
                      ? `Total Price Range: $0 ~ $${buyback.charge.toFixed(2)}`
                      : `Total Charge: $${buyback.charge.toFixed(2)}`}
                  </Typography>
                  <Typography variant="body2">
                    Payment Method: {buyback.payment_method}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    style={{ whiteSpace: "pre-line" }}
                  >
                    Comment: <br />
                    {buyback.comment_list.length === 0
                      ? "None"
                      : buyback.comment_list.join("\n")}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default BuybackApplication;
