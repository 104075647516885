import './App.css';
import Auth from "./auth/auth.component";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from "./main"
import Profile from './profile/index';
import Dashboard from "./profile/content/Dashboard";
import OrderStatus from "./profile/content/OrderStatus";
import InvoiceStatus from "./profile/content/InvoiceStatus";
import DeviceStatus from "./profile/content/DeviceStatus";
import BuybackApplication from "./profile/content/BuybackApplication";
import BuybackReport from "./profile/content/BuybackReport";
// import ProductList from './main/products/productlist';
import ProductDetailPage from './main/products/product_detail_page';
import ShoppingCartPage from './cart/shopping_cart_page';
import ProductCompleteListPage from './main/products/product_complete_list';
import PublicityPage from './publicity/publicity_page';
import ChangePassword from './profile/content/ChangePassword';
import MobileRegistration from './auth/registration/mobileregistration';
import BuybackPricingTable from './buyback/BuybackPricingTable';
import BuybackLabel from './buyback/BuybackLabel';
import ResetPassword from './auth/ResetPassword';
import Privacy from './publicity/privacy';
import PaymentPage from './cart/payment_page';
import CheckoutComplete from './cart/checkout_complete';
import ScrollToTop from './ScrollToTop';

function App() {
  const state = {};
  const  PageNotFound=()=> {
    return (
      <div>
        <p>404 Page not found</p>
      </div>
    );
  }
  return (
    <Router>
      <ScrollToTop />
      <div className="myApp">
        <Routes>
          {/* <Route path="/" element={<Main state={state} />} /> */}
          <Route path="/main" element={<Main state={state} />} />
          <Route path="/auth" element={<Auth state={state} />} />
          <Route path='/mobileregister' element={<MobileRegistration state={state}/>}/>
          <Route path="/account" element={<Profile state={state} />} />
          <Route path="/account/dashboard" element={<Dashboard state={state} />} />
          <Route path="/account/orders" element={<OrderStatus state={state} />} />
          <Route path="/account/buyback" element={<BuybackApplication state={state} />} />
          <Route path="/account/buyback-report" element={<BuybackReport state={state} />} />
          <Route path="/account/devices" element={<DeviceStatus state={state} />} />
          <Route path="/account/invoices" element={<InvoiceStatus state={state} />} />
          <Route path="/products" state={state}>
            <Route path="" element={<PageNotFound state={state} />} />
            <Route path={":productId"} element={< ProductDetailPage state={state} />} />
          </Route>
          <Route path="/shoppingcart" element={<ShoppingCartPage state={state} />} />
          <Route path="/productlist" state={state}>
            <Route path="" element={<PageNotFound state={state} />} />
            <Route path={":productName"} element={<ProductCompleteListPage state={state} />} />
          </Route>
          <Route path="/" element={<PublicityPage state={state} />} />
          <Route path='/account/changepassword' element={<ChangePassword state={state}/>}/>
          <Route path="/buyback" element={<BuybackPricingTable state={state} />} />
          <Route path="/buyback/label" element={<BuybackLabel state={state} />} />
          <Route path="/reset" element={<ResetPassword state={state}/>} />
          <Route path="/privacy" element={<Privacy state={state}/>}/>
          <Route path="/payment" element={<PaymentPage state={state}/>}/>
          <Route path="/checkout-complete" element={<CheckoutComplete state={state}/>} />
          <Route path="*" element={<Main state={state} />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
