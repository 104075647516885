import React, { useState, useEffect } from "react";
import AppbarDesktop from "../../main/appbar";
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Input,
  Typography,
  Paper,
} from "@mui/material";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { updateLogin } from "../../actions";
import { url } from "../../url";
import { useMediaQuery } from "react-responsive";
import MobileMenu from "../../main/menu/mobile_menu";
import LeftMenu from "../left_menu/menu";
import Menu from "../../main/menu/menu";
import IconButton from "@mui/material/IconButton";
import AWS from "aws-sdk";
import DescriptionIcon from "@mui/icons-material/Description";

const Dashboard = (props) => {
  const islogin = useSelector((state) => state.islogin);
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const [userInfor, setUserInfor] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [isMenuOpen, setIsMenuOpen] = useState(false);
  // const [notification, setNotification] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageURL, setImageURL] = useState("");

  useEffect(() => {
    document.title = "Lenwell | DashBoard"; //
  }, []);

  useEffect(() => {
    AWS.config.update({
      accessKeyId: "AKIATQ4Z2DERYT2SGE4I",
      secretAccessKey: "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",
      region: "us-east-2",
    });
  }, []);

  //   const handleMenuClick = () => {
  //     handleMenuToggle();
  //     // console.log('Menu button clicked');
  //   };

  //   const handleMenuToggle = () => {
  //     setIsMenuOpen(true);
  //   };

  useEffect(() => {
    fetch(url("/b/customer/profile/"), {
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 403) {
          Cookies.set("islogin", "false", { path: "/" });
          Cookies.remove("csrftoken");
          const action = updateLogin(false);
          dispatch(action);
          navigate("/auth");
        }
        if (response.status === 404) {
          navigate("/main");
        }
        return response.json();
      })
      .then((data) => {
        setUserInfor(data);
        setImageURL(data.customers.tax_url);
        // console.log(data.customers);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [dispatch, navigate]);

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = () => {
    console.log(userInfor.customers);
    if (selectedFile) {
      const params = {
        Bucket: "imagelenwell",
        Key:
          `tax-documents/${Date.now()}-${userInfor.customers.company}` +
          "." +
          selectedFile.name.split(".").pop(),
        Body: selectedFile,
        ACL: "public-read",
        ContentType: selectedFile.type,
      };

      const s3 = new AWS.S3();
      s3.upload(params, (err, data) => {
        if (err) {
          console.log("Upload failed:", err);
        } else {
          // setNotification("Upload successful")
          fetch(url("/b/customer/update_tax_file/"), {
            method: "PUT",
            credentials: "include",
            headers: { "X-CSRFToken": Cookies.get("token") },
            body: JSON.stringify({ tax_url: data.Location }),
          })
            .then((res) => {
              if (res.status === 403) {
                Cookies.set("islogin", "false", { path: "/" });
                Cookies.remove("csrftoken");
                navigate("/auth");
              }
              if (res.status === 404) {
                navigate("/main");
              }
              return res.json();
            })
            .then((res) => {
              alert(res.result);
              if (res.result === "success") {
                console.log("Upload successful. Image URL:", data.Location);
                setImageURL(data.Location);
                console.log(data.Location);
                console.log(imageURL); //why there is origin url?
                navigate("/account/dashboard");
              }
            });
        }
      });
    }
  };

  if (islogin === false) {
    return <Navigate to="/" />;
  }

  if (userInfor === null) {
    return (
      <Box>
        <AppbarDesktop />
      </Box>
    );
  } else {
  }

  return (
    <Box>
      <AppbarDesktop />
      {isMobile ? <MobileMenu sx={{ flexGrow: 1 }} /> : <Menu />}
      {isMobile ? <Link to="/account">return to account menu</Link> : null}

      <Box
        sx={{
          marginLeft: !isMobile && "12%",
          marginRight: !isMobile && "10%",
          display: "flex",
          flex: 1,
          alignItems: isMobile ? "center" : "stretch",
          marginTop: isMobile ? 5 : 0,
          flexDirection: isMobile ? "column" : "row",
          paddingBottom: "2%",
        }}
      >
        {!isMobile && (
          <Paper>
            <LeftMenu />
          </Paper>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginLeft: !isMobile && "50px",
            width: "90%",
          }}
        >
          {/* <Paper sx={{ width: "100%" }}> */}
          <Card sx={{ marginBottom: "30px", minWidth: !isMobile && 450 }}>
            <CardHeader
              title={"Contact Information"}
              style={{ backgroundColor: "#757de8", color: "white" }}
            />
            <CardContent>
              <Box sx={{ marginTop: "15px" }}>
                <Typography>
                  {userInfor.customers.first_name}{" "}
                  {userInfor.customers.last_name}
                </Typography>
                <Typography>{userInfor.customers.email}</Typography>
                <Typography>{userInfor.customers.phone}</Typography>
                <Link to={{ pathname: "/account/changepassword" }}>
                  <Typography>change password</Typography>
                </Link>
              </Box>
            </CardContent>
          </Card>

          <Card sx={{ marginBottom: "30px", minWidth: !isMobile && 450 }}>
            <CardHeader
              title={"Default Shipping Address"}
              style={{ backgroundColor: "#757de8", color: "white" }}
            />
            <CardContent>
              <Box sx={{ marginTop: "15px" }}>
                <Typography>
                  {userInfor.customers.first_name}{" "}
                  {userInfor.customers.last_name}
                </Typography>
                <Typography>{userInfor.customers.address1}</Typography>
                <Typography>{userInfor.customers.address2}</Typography>
                <Typography>
                  {userInfor.customers.city}, {userInfor.customers.state},{" "}
                  {userInfor.customers.zipcode}
                </Typography>
                <Typography>{userInfor.customers.phone}</Typography>
              </Box>
            </CardContent>
          </Card>

          <Card sx={{ minWidth: !isMobile && 450 }}>
            <CardHeader
              title={"TaxID File"}
              style={{ backgroundColor: "#757de8", color: "white" }}
              action={
                <IconButton
                  sx={{ color: "white" }}
                  href={imageURL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <DescriptionIcon />
                </IconButton>
              }
            />
            <CardContent>
              <Typography>
                {userInfor.customers.company
                  ? userInfor.customers.company
                  : " "}
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: "1",
                  }}
                >
                  <Typography>Upload Your Tax ID File</Typography>
                  <Input
                    type="file"
                    onChange={handleFileSelect}
                    inputProps={{
                      accept: "image/*,application/pdf",
                    }}
                  />
                </Box>
                <Button variant="outlined" onClick={handleUpload}>
                  Upload
                </Button>
              </Box>
            </CardContent>
          </Card>
          {/* </Paper> */}
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
