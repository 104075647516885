import React, { useEffect } from "react";
import { Box, Typography, CardMedia, Button } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import Footer from "./footer";

const Privacy = () => {
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const nagivate = useNavigate();
  useEffect(() => {
    document.title = "Lenwell | Privacy"; //
  }, []);
  return (
    <Box>
      {isMobile ? (
        <Box
          sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              flexGrow: 1,
            }}
          >
            <CardMedia
              component="img"
              alt="publicimage"
              style={{ width: 120, height: 40, marginTop: "100px" }}
              className="image"
              image="https://imagelenwell.s3.us-east-2.amazonaws.com/logo/Lenwell-Digital-Logo-No-BG.png"
            />
            <Button
              onClick={() => {
                nagivate("/");
              }}
            >
              Return Main page
            </Button>

            <Typography sx={{ marginTop: "40px" }}>PRIVACY POLICY</Typography>
            <Typography
              sx={{ width: "70%", marginTop: "30px", marginBottom: "60px" }}
            >
              At our website, we use cookies solely to track your login status
              and ensure a smooth browsing experience. We want to emphasize that
              these cookies do not collect any other personal information. Your
              privacy is of utmost importance to us, and we are committed to
              maintaining a secure and transparent environment. Rest assured
              that our cookie usage is limited to maintaining your session and
              does not involve tracking or storing any personal data. If you
              have any concerns or questions regarding our privacy practices,
              please feel free to reach out to us.
            </Typography>
          </Box>
          <Footer />
        </Box>
      ) : (
        <Box
          sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              flexGrow: 1,
            }}
          >
            <CardMedia
              component="img"
              alt="publicimage"
              style={{ width: 120, height: 40, marginTop: "100px" }}
              className="image"
              image="https://imagelenwell.s3.us-east-2.amazonaws.com/logo/Lenwell-Digital-Logo-No-BG.png"
            />
            <Button
              onClick={() => {
                nagivate("/");
              }}
            >
              Return Main page
            </Button>

            <Typography sx={{ marginTop: "40px" }}>PRIVACY POLICY</Typography>
            <Typography sx={{ width: "50%", marginTop: "30px" }}>
              At our website, we use cookies solely to track your login status
              and ensure a smooth browsing experience. We want to emphasize that
              these cookies do not collect any other personal information. Your
              privacy is of utmost importance to us, and we are committed to
              maintaining a secure and transparent environment. Rest assured
              that our cookie usage is limited to maintaining your session and
              does not involve tracking or storing any personal data. If you
              have any concerns or questions regarding our privacy practices,
              please feel free to reach out to us.
            </Typography>
          </Box>
          <Footer />
        </Box>
      )}
    </Box>
  );
};
export default Privacy;
