import {
  PaymentElement,
  // LinkAuthenticationElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { Button, Typography } from "@mui/material";
// import { url } from "../url";
// import Cookies from "js-cookie";
// import { useNavigate } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import { useSelector } from "react-redux";
// import { removeFromCart } from "../actions";
import CircularProgress from "@mui/material/CircularProgress";

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  // const [email, setEmail] = useState("");
  const [message, setMessage] = useState(null);
  // const navigate = useNavigate();
  // const dispatch = useDispatch();
  // var cart = useSelector((state) => {
  //   return state.cart;
  // });
  // const [cartItems, setCartItems] = useState(cart);

  // const removeItem = () => {
  //   var fetches = [];
  //   console.log(cartItems);
  //   for (const peritem of cartItems) {
  //     const updatedCartItems = cartItems.filter(
  //       (item) => item.product.uuid !== peritem.product.uuid
  //     );
  //     setCartItems(updatedCartItems);
  //     const action = removeFromCart(peritem.product.uuid);
  //     dispatch(action);
  //     fetches.push(
  //       fetch(url("/b/cart/delete/"), {
  //         method: "DELETE",
  //         credentials: "include",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //           "X-CSRFToken": Cookies.get("token"),
  //         },
  //         body: JSON.stringify({ uuid: peritem.product.uuid }),
  //       }).then((res) => {
  //         if (res.status === 403) {
  //           Cookies.set("islogin", "false", { path: "/" });
  //           Cookies.remove("csrftoken");
  //           navigate("/auth");
  //         }
  //         if (res.status === 404) {
  //           navigate("/main");
  //         }
  //       })
  //     );
  //     Promise.all(fetches).then((res) => {
  //       console.log("delete all");
  //     });
  //   }
  // };

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    console.log("checkout button test!");
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    // removeItem();
    const { error } = await stripe.confirmPayment({
      //only fails return a promise
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: "http://localhost:3000/checkout-complete",
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }
    console.log("remove all item test!");

    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <Button
        disabled={isLoading || !stripe || !elements}
        variant="contained"
        color="primary"
        id="submit"
        onClick={handleSubmit}
        sx={{ marginTop: "20px" }}
      >
        <span id="button-text">
          {isLoading ? (
            <CircularProgress size={"24px"} />
          ) : (
            <Typography>Pay now ${(props.amount / 100).toFixed(2)} </Typography>
          )}
        </span>
      </Button>
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
};

export default CheckoutForm;
