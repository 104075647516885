import React, { useEffect } from "react";
// import React, { useState, useEffect } from "react";
import { AppBar, Typography, Button, Box } from "@mui/material";
// import { useDispatch } from 'react-redux';
// import { updateLogin } from '../actions'
import { NavLink } from "react-router-dom";
// import { useNavigate } from 'react-router-dom';
// import { Padding } from '@mui/icons-material';
import LoginIcon from "@mui/icons-material/Login";
// import { NavLink } from "react-router-dom";
// import { useSelector } from "react-redux";
// import { useDispatch } from "react-redux";
// import { updateLogin, setToken } from "../actions";
// import { useNavigate } from "react-router-dom";
// import Cookies from "js-cookie";
// import { url } from "../url";
import CookiePrompt from "./cookie_prompt";
import Footer from "./footer";

const PublicityPage = () => {
  //   const dispatch = useDispatch();
  //   const navigate = useNavigate();
  //   useEffect(() => {
  //     //detect data, rerender when data is changed.
  //     // fetchdate();
  //   }, []);
  //   const fetchdate = () => {
  //     //use anonymous function in function component
  //     fetch(url("/b/customer/token/"), {
  //       method: "POST",
  //       credentials: "include",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Accept: "application/json",
  //       },
  //     })
  //       .then((response) => {
  //         return response.json();
  //       })
  //       .then()
  //       .catch((error) => {
  //         console.error(error);
  //       });
  //   };
  useEffect(() => {
    document.title = "Lenwell"; //
  }, []);
  return (
    <Box
      sx={{}}
      style={{
        backgroundImage:
          "url('https://imagelenwell.s3.us-east-2.amazonaws.com/logo/joel-rohland-MCL2xxjZJvs-unsplash-scaled.jpg')",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <AppBar
        position="static"
        sx={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "rgba(255, 255, 255, 0.1)",
        }}
      >
        <img
          alt="publicimage"
          style={{ width: 120, height: 40, marginTop: 3 }}
          className="image"
          src="https://imagelenwell.s3.us-east-2.amazonaws.com/logo/Lenwell-Digital-Logo-No-BG.png"
        />
        <Box
          sx={{ display: "felx", flexDirection: "row-reverse", flexGrow: 1 }}
        ></Box>
        <NavLink to={{ pathname: "/auth" }}>
          <Button
            sx={{ height: "100%" }}
            variant="contained"
            color="primary"
            onClick={() => {}}
          >
            Register/Login
            <LoginIcon />
          </Button>
        </NavLink>
      </AppBar>
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Box sx={{}}>
          <Typography variant="h3" color="whitesmoke">
            Welcome to Lenwell Digital{" "}
          </Typography>
          {/* <Typography>Lenwell is a professional cell phone screen repair shop registered in Houston, Texas. Our services include iWatch, iPhone, iPAD and Samsung cell phone screen repairs. We also provide replacement screens if you choose to save on repair costs by installing the new screens by yourself if you know how. Our customers include cell phone stores and consumers. We are probably the only repair shop in US that repairs screens on full line of Apple and Samsung cell phones and tablets on daily basis. We have fixed damaged screens on more than 30000 Apple and Samsung devices since 2015. Cell phone stores are welcome to contact us for partnership opportunities for more customers, lower repair costs and faster turnaround.</Typography> */}
        </Box>
        <NavLink to={{ pathname: "/auth" }}>
          <Button variant="contained" style={{}}>
            <Typography variant="h3" style={{ color: "red" }}>
              SIGNUP
            </Typography>
          </Button>
        </NavLink>
        <Box sx={{}}>
          <video
            // ref={videoRef}
            width="100%"
            height="480"
            controls
            autoPlay
            muted
          >
            <source
              src="https://imagelenwell.s3.us-east-2.amazonaws.com/logo/1686674641065082.mp4"
              type="video/mp4"
            />
          </video>
        </Box>

        <Typography variant="h4" style={{ color: "red", marginTop: "30px" }}>
          About Lenwell
        </Typography>
        <Box
          style={{
            width: "70%",
            color: "white",
            fontSize: "25px",
            fontFamily: "Helvetica",
            border: "1px solid lightblue",
            borderRadius: "8px",
            padding: "10px",
          }}
        >
          <Typography>
            {" "}
            Lenwell is a professional screen repair shop. Our technicians have
            extensive expertise in Apple and Samsung devices. Years ago, we
            discovered that there was a surplus of broken cell-phone glasses and
            limited options available at the time. You could either send it
            overseas for refurbishment or dump it in the trash. Consequently,
            many devices were discarded regardless of the severity of the
            damage. In order to help reduce this waste, Lenwell has developed
            skills and upgraded equipments to fix all sorts of screen damage.
            Today, Lenwell has the capability to fix all iPhone, iPad, iWatch,
            and Samsung mobile device models. From front glass, digitizer
            (screen touchpad), LCD screen to the back glass, we have refurbished
            parts ready in stock to expedite the repair process.
          </Typography>
        </Box>
        <br />
        <br />
        <br />
        <br />
      </Box>
      <CookiePrompt />
      <Footer />
    </Box>
  );
};

export default PublicityPage;
