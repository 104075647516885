import React, { useState, useRef } from "react";
import { MenuContianer } from "../../styles/menu";
import {
  Box,
  Button,
  Divider,
  Paper,
  Popper,
  ClickAwayListener,
} from "@mui/material";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import menudata from "./menu_data.json";
import button_map from "./compatibility.json";
import { NavLink } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { styled } from "@mui/material/styles";

const CustomScrollbarBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  padding: 8,
  borderRight: "1px solid #ddd",
  height: "500px",
  overflow: "auto",
  scrollbarWidth: "thin",
  scrollbarColor: "#888 #f1f1f1",
  "&::-webkit-scrollbar": {
    // Chrome, Safari, Edge
    width: 2,
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#888",
    borderRadius: "4px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#555",
  },
  "&::-webkit-scrollbar-track": {
    background: "#f1f1f1",
  },
}));

const Menu = () => {
  const [page_id, setPage_id] = useState("");
  const [product_name, setProduct_name] = useState("");
  const [popover2, setPopover2] = useState({ anchorEl: null });

  const [open, setOpen] = useState(false);
  //   const [clickOrigin, setClickOrigin] = useState(null);
  //   const anchorRef = useRef(null);
  const popperRef = useRef(null);
  const buttonRef = useRef([]);
  const centerRef = useRef(null);
  const isMobile = useMediaQuery({ maxWidth: 600 });

  //   const handleClickOutside = (event) => {
  //     if (
  //       anchorRef.current &&
  //       !anchorRef.current.contains(event.target) &&
  //       popperRef.current &&
  //       !popperRef.current.contains(event.target)
  //     ) {
  //       setOpen(false);
  //     }
  //   };

  //   const handleOutsideClick = () => {
  //     console.log("outside click")
  //     if (open) {
  //       setOpen(false);
  //     }
  //   };

  //   const handleToggle = () => {
  //     setOpen((prevOpen) => !prevOpen);
  //   };

  const MyPopover = (pageid) => {
    // const pageid = pageid;
    // console.log(pageid)
    switch (pageid) {
      case "Apple":
        var Apple_product = menudata[0]["subMenuItems"];
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "stretch",
              padding: 1,
            }}
          >
            {/* <Typography>Apple</Typography> */}
            {Apple_product.map((data, i) => (
              <CustomScrollbarBox key={"Apple " + i}>
                <Typography>{data["label"]}</Typography>
                {data["subMenuItems"].map((subdata, i) => (
                  <NavLink
                    key={subdata.label + " " + i}
                    to={{
                      pathname:
                        "/productlist/" + encodeURIComponent(subdata.label),
                    }}
                  >
                    <Button
                      style={{
                        textTransform: "none",
                        justifyContent: "flex-start",
                      }}
                      onMouseEnter={() => handleMouseEnter(subdata.name)}
                      onMouseLeave={() => handleMouseEnter()}
                      onClick={() => {
                        handlePopover2Close();
                      }}
                      sx={{
                        padding: 0,
                        borderRadius: "5px",
                        "&:hover": { backgroundColor: "red" },
                      }}
                    >
                      {subdata.label}
                    </Button>
                  </NavLink>
                ))}
              </CustomScrollbarBox>
            ))}
          </Box>
        );
      // break;
      case "Samsung":
        var Samsung_product = menudata[1]["subMenuItems"];
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "stretch",
              padding: 1,
            }}
          >
            {/* <Typography>Apple</Typography> */}
            {Samsung_product.map((data, i) => (
              <CustomScrollbarBox key={"Samsung " + i}>
                <Typography>{data["label"]}</Typography>
                {data["subMenuItems"].map((subdata, i) => (
                  <NavLink
                    key={subdata.label + " " + i}
                    to={{
                      pathname:
                        "/productlist/" + encodeURIComponent(subdata.label),
                    }}
                  >
                    <Button
                      style={{
                        textTransform: "none",
                        justifyContent: "flex-start",
                      }}
                      onMouseEnter={() => handleMouseEnter(subdata.name)}
                      onMouseLeave={() => handleMouseEnter()}
                      onClick={() => {
                        handlePopover2Close();
                      }}
                      sx={{
                        padding: 0,
                        borderRadius: "5px",
                        "&:hover": { backgroundColor: "red" },
                      }}
                    >
                      {subdata.label}
                    </Button>
                  </NavLink>
                ))}
              </CustomScrollbarBox>
            ))}
          </Box>
        );
      // break;
      case "Microsoft":
        var Microsoft_product = menudata[2]["subMenuItems"];
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "stretch",
              padding: 1,
            }}
          >
            {/* <Typography>Apple</Typography> */}
            {Microsoft_product.map((data, i) => (
              <CustomScrollbarBox key={"Microsoft " + i}>
                <Typography>{data["label"]}</Typography>
                {data["subMenuItems"].map((subdata, i) => (
                  <NavLink
                    key={subdata.label + " " + i}
                    to={{
                      pathname:
                        "/productlist/" + encodeURIComponent(subdata.label),
                    }}
                  >
                    <Button
                      style={{
                        textTransform: "none",
                        justifyContent: "flex-start",
                      }}
                      onMouseEnter={() => handleMouseEnter(subdata.name)}
                      onMouseLeave={() => handleMouseEnter()}
                      onClick={() => {
                        handlePopover2Close();
                      }}
                      sx={{
                        padding: 0,
                        borderRadius: "5px",
                        "&:hover": { backgroundColor: "red" },
                      }}
                    >
                      {subdata.label}
                    </Button>
                  </NavLink>
                ))}
              </CustomScrollbarBox>
            ))}
          </Box>
        );
      // break;
      case "Accessories":
        var other_product = menudata[3]["subMenuItems"];
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "stretch",
              padding: 1,
            }}
          >
            {/* <Typography>Apple</Typography> */}
            {other_product.map((data, i) => (
              <CustomScrollbarBox key={"Others " + i}>
                <Typography>{data["label"]}</Typography>
                {data["subMenuItems"].map((subdata, i) => (
                  <NavLink
                    key={subdata.label + " " + i}
                    to={{
                      pathname:
                        "/productlist/" + encodeURIComponent(subdata.label),
                    }}
                  >
                    <Button
                      style={{
                        textTransform: "none",
                        justifyContent: "flex-start",
                      }}
                      onMouseEnter={() => handleMouseEnter(subdata.name)}
                      onMouseLeave={() => handleMouseEnter()}
                      onClick={() => {
                        handlePopover2Close();
                      }}
                      sx={{
                        padding: 0,
                        borderRadius: "5px",
                        "&:hover": { backgroundColor: "red" },
                      }}
                    >
                      {subdata.label}
                    </Button>
                  </NavLink>
                ))}
              </CustomScrollbarBox>
            ))}
          </Box>
        );
      // break;
      case "Special Promotion":
        var special_glass_product = menudata[4]["subMenuItems"];
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "stretch",
              padding: 1,
            }}
          >
            {/* <Typography>Apple</Typography> */}
            {special_glass_product.map((data, i) => (
              <CustomScrollbarBox key={"Special " + i}>
                <Typography>{data["label"]}</Typography>
                {data["subMenuItems"].map((subdata, i) => (
                  <Button
                    style={{
                      textTransform: "none",
                      justifyContent: "flex-start",
                    }}
                    key={subdata.label + " " + i}
                    onMouseEnter={() => handleMouseEnter(subdata.name)}
                    onMouseLeave={() => handleMouseEnter()}
                    sx={{
                      padding: 0,
                      borderRadius: "5px",
                      "&:hover": { backgroundColor: "red" },
                    }}
                  >
                    {subdata.label}
                  </Button>
                ))}
              </CustomScrollbarBox>
            ))}
          </Box>
        );
      // break;
      default:
        return;
    }
  };

  const handleMouseEnter = (name) => {
    setProduct_name(name);
  };

  //   const handleMouseLeave = () => {
  //     setProduct_name("");
  //   };

  const handlePopover2Open = (mypage, event) => {
    if (mypage === "Brands" || mypage === "Special Promotion") {
      alert("It will be available soon.");
      return;
    }
    setOpen(true);
    setPage_id(mypage);
    var popover2 = {
      anchorEl: centerRef.current,
    };
    // console.log(event.currentTarget)
    setPopover2(popover2);
    // console.log("click:" + open)
  };

  const handlePopover2Close = () => {
    setPage_id("");
    setOpen(false);
    var popover2 = {
      anchorEl: null,
    };
    setPopover2(popover2);
  };

  const handleClickAway = (event) => {
    if (
      buttonRef.current.includes(event.target) ||
      (popperRef.current && popperRef.current.contains(event.target))
    ) {
      return;
    }
    setOpen(false);
  };

  //   const getButtonTopPosition = () => {
  //     const buttonRect = buttonRef.current[0].getBoundingClientRect();
  //     return buttonRect.top;
  //   };

  //   const getCenteredHorizontalPosition = () => {
  //     return window.innerWidth / 2;
  //   };

  return (
    <Box>
      {isMobile ? (
        <Box></Box>
      ) : (
        <MenuContianer sx={{}}>
          <Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              {menudata.map((data, i) => (
                <Box
                  key={data.label + " " + i}
                  sx={{ display: "flex", flexDirection: "row" }}
                >
                  <Button
                    disabled={data.label === "Special Promotion"}
                    ref={(ref) => (buttonRef.current[i] = ref)}
                    onClick={handlePopover2Open.bind(this, data.page)}
                  >
                    {data.label}
                  </Button>
                  <Box style={{ width: "50px", height: "1px" }}></Box>
                </Box>
              ))}
              <Button component={Link} to={"/buyback"}>
                LCD Buyback
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <span ref={centerRef} style={{ width: "1px" }}></span>
            </Box>
          </Box>

          <ClickAwayListener onClickAway={handleClickAway}>
            <Popper
              id="samsung"
              open={open}
              anchorEl={popover2.anchorEl}
              // onClose={handlePopover2Close}
              ref={popperRef}
              placement="bottom"
            >
              <Paper>
                <Box
                  sx={{
                    height: 600,
                    width: 1200,
                    display: "flex",
                    flexDirection: "column",
                    // justifyContent: 'center',
                  }}
                >
                  <Box
                    sx={{
                      height: "100px",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Typography padding={1} variant="h3">
                      {" "}
                      Display Compatibility
                    </Typography>

                    {/* display compatibility */}
                    <p>
                      {product_name === ""
                        ? ""
                        : product_name in button_map
                        ? button_map[product_name]["compatibility_model"]
                        : ""}
                    </p>
                  </Box>
                  <Divider />
                  {MyPopover(page_id)}
                  {/* <MyPopover pageid={this.state.page_id} /> */}
                </Box>
              </Paper>
            </Popper>
          </ClickAwayListener>
        </MenuContianer>
      )}
    </Box>
  );
};

export default Menu;
