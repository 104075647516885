import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Container,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  IconButton,
  Input,
  Box,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import AppbarDesktop from "../main/appbar/appbarDesktop";
import Menu from "../main/menu/menu";
import { removeFromCart, updateToCart } from "../actions";
import { useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import MobileMenu from "../main/menu/mobile_menu";
import Cookies from "js-cookie";
import { url } from "../url";
import { initializeCart } from "../actions";

const ShoppingCartPage = () => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 600 });
  var cart = useSelector((state) => state.cart);
  const isLogin = useSelector((state) => state.islogin);
  const [cartItems, setCartItems] = useState(cart);
  const [islogin, setIsLogin] = useState(isLogin);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Lenwell | ShoppingCart"; //
  }, []);

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetch(url("/b/cart/"), {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 403) {
          Cookies.set("islogin", "false", { path: "/" });
          Cookies.remove("csrftoken");
          navigate("/auth");
        }
        if (response.status === 404) {
          navigate("/main");
        }
        return response.json();
      })
      .then((res) => {
        const data = res.cart.map((item) => {
          return { product: item, quantity: item.quantity };
        });
        var action = initializeCart(data);
        dispatch(action);
        setCartItems(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [navigate, dispatch]);

  const onBlurHandle = (product, value) => {
    if (value !== value) {
      value = 1;
    }
    updateQuantity(product, value);
  };

  const updateQuantity = (product, newQuantity) => {
    // console.log(product)
    console.log(newQuantity);

    if (newQuantity < 1) return;
    if (newQuantity > 999) newQuantity = 999;
    var itemId = product.product.id;
    const updatedCartItems = cartItems.map((item) => {
      if (item.product.id === itemId) {
        return { ...item, quantity: newQuantity };
      }
      return item;
    });
    if (newQuantity === newQuantity) {
      fetch(url("/b/cart/update/"), {
        method: "PUT",
        credentials: "include",
        headers: { "X-CSRFToken": Cookies.get("token") },
        body: JSON.stringify({
          uuid: product.product.uuid,
          quantity: newQuantity,
        }),
      }).then((res) => {
        if (res.status === 403) {
          Cookies.set("islogin", "false", { path: "/" });
          Cookies.remove("csrftoken");
          navigate("/auth");
        }
        if (res.status === 404) {
          navigate("/main");
        }
        return res.json();
      });
    }

    setCartItems(updatedCartItems);
    const action = updateToCart(product.product, newQuantity);
    dispatch(action);
  };

  const removeItem = (product) => {
    console.log(product);
    const updatedCartItems = cartItems.filter(
      (item) => item.product.uuid !== product.uuid
    );
    fetch(url("/b/cart/delete/"), {
      method: "DELETE",
      credentials: "include",
      headers: { "X-CSRFToken": Cookies.get("token") },
      body: JSON.stringify({ uuid: product.uuid }),
    }).then((res) => {
      if (res.status === 403) {
        Cookies.set("islogin", "false", { path: "/" });
        Cookies.remove("csrftoken");
        navigate("/auth");
      }
      if (res.status === 404) {
        navigate("/main");
      }
    });

    setCartItems(updatedCartItems);
    const action = removeFromCart(product.uuid);
    dispatch(action);
  };

  const getTotalPrice = () => {
    return cartItems
      .reduce((total, item) => total + item.product.price * item.quantity, 0)
      .toFixed(2);
  };
  const handlePayment = () => {
    if (getTotalPrice() < 0.01) {
      alert(
        "Your shopping cart is empty. Please add items before proceeding to payment."
      );
      return;
    }
    navigate("/payment");
    // alert("It will be available soon. ");
  };
  if (islogin === false) {
    return <Navigate to="/" />;
  }

  return (
    <Box>
      <Box>
        {isMobile ? (
          <Box>
            <AppbarDesktop />
            <MobileMenu sx={{ flexGrow: 1 }} />
            <Link to="/main">return to main</Link>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography variant="h5">Shopping Cart</Typography>
              {cartItems.map((item) => (
                <Card
                  key={item.product.uuid}
                  sx={{
                    width: 300,
                    marginBottom: 3,
                  }}
                >
                  <CardContent>
                    <Typography variant="h6">
                      Model:{item.product.product_name}
                    </Typography>
                    <img
                      src={item.product.image_url}
                      alt={item.product.product_name}
                      style={{ width: "100px" }}
                    />

                    <Typography variant="body2">
                      Price: ${item.product.price}
                    </Typography>
                    <Typography variant="body2">
                      Amount:
                      <Input
                        as="span"
                        type="number"
                        value={item.quantity}
                        onChange={(e) =>
                          updateQuantity(item, parseInt(e.target.value))
                        }
                        inputProps={{ min: 1 }}
                      />{" "}
                    </Typography>

                    <Typography variant="body2">
                      Total Due: $
                      {(item.product.price * item.quantity).toFixed(2)}
                    </Typography>
                    <IconButton
                      color="secondary"
                      onClick={() => removeItem(item.product)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </CardContent>
                </Card>
              ))}

              <Typography>
                Sum total excludes shipping: ${getTotalPrice()}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={handlePayment}
                disabled
                sx={{ marginBottom: "60px" }}
              >
                Checkout
              </Button>
            </Box>
          </Box>
        ) : (
          <Box sx={{ marginBottom: "300px" }}>
            <AppbarDesktop />
            <Menu />
            <Container maxWidth="md" sx={{ marginBottom: "60px" }}>
              <Typography
                variant="h4"
                component="h1"
                align="center"
                gutterBottom
              >
                Shopping Cart
              </Typography>

              <Button
                component={Link}
                to="/main"
                variant="outlined"
                color="primary"
                style={{ marginBottom: "20px" }}
              >
                Return Main
              </Button>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Image</TableCell>
                      <TableCell>Model</TableCell>
                      <TableCell>Price</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Total</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {cartItems.map((item) => (
                      <TableRow key={item.product.id}>
                        <TableCell>
                          <img
                            src={item.product.image_url}
                            alt={item.product.product_name}
                            style={{ width: "100px" }}
                          />
                        </TableCell>
                        <TableCell>{item.product.product_name}</TableCell>
                        <TableCell>${item.product.price}</TableCell>
                        <TableCell>
                          <Input
                            type="number"
                            value={item.quantity}
                            onChange={(e) =>
                              updateQuantity(item, parseInt(e.target.value))
                            }
                            onBlur={(e) => {
                              onBlurHandle(item, parseInt(e.target.value));
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          ${(item.product.price * item.quantity).toFixed(2)}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            color="secondary"
                            onClick={() => removeItem(item.product)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell colSpan={4} align="right">
                        Sum total excludes shipping
                      </TableCell>
                      <TableCell>${getTotalPrice()}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handlePayment}
                          // disabled
                        >
                          Checkout
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Container>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            ></Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ShoppingCartPage;
